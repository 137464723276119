import { useStyletron } from "baseui"
import { Search } from "baseui/icon"
import React from "react"

import { TEST_IDS } from "../../../../config/test-ids"

import ClearButton from "./clear-button"
import CardTitle from "./card-title"

interface Props {
    readonly isActive: boolean
    readonly input: string
    readonly setInput: (input: string) => void
    readonly setIsSearching: (input: boolean) => void
}

export default function SearchTile({
    isActive,
    input,
    setInput,
    setIsSearching,
}: Props) {
    const styles = useStyles()
    return (
        <>
            <CardTitle
                title="Search"
                isActive={isActive}
                activeComponent={
                    input ? <ClearButton onClick={() => setInput("")} /> : null
                }
                inactiveComponent={
                    <p className={styles.cardHelpText}>{input || "Any"}</p>
                }
            />
            {isActive && (
                <div className={styles.searchWrapper}>
                    <div
                        className={styles.searchTrigger}
                        onClick={() => setIsSearching(true)}
                        data-testid={TEST_IDS.MOBILE_SEARCH_TILE}
                    >
                        <Search size={22} className={styles.searchIcon} />
                        <p
                            className={
                                input
                                    ? styles.searchText
                                    : styles.searchPlaceholder
                            }
                        >
                            {input || "Search locations, species, listings..."}
                        </p>
                    </div>
                </div>
            )}
        </>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        cardTitle: css({
            fontSize: "1.3rem",
            fontWeight: "bold",
        }),
        inactiveTitle: css({
            fontSize: "0.9rem",
            fontWeight: "600",
            color: theme.colors.contentTertiary,
        }),
        inactiveWrapper: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
        }),
        cardHelpText: css({
            fontSize: "0.9rem",
            fontWeight: "600",
        }),
        searchWrapper: css({
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
            marginBottom: theme.sizing.scale800,
        }),
        searchTrigger: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderLeftStyle: "solid",
            borderBottomStyle: "solid",
            borderTopColor: theme.colors.contentInverseTertiary,
            borderRightColor: theme.colors.contentInverseTertiary,
            borderBottomColor: theme.colors.contentInverseTertiary,
            borderLeftColor: theme.colors.contentInverseTertiary,
            borderTopLeftRadius: theme.sizing.scale400,
            borderTopRightRadius: theme.sizing.scale400,
            borderBottomRightRadius: theme.sizing.scale400,
            borderBottomLeftRadius: theme.sizing.scale400,
            paddingTop: theme.sizing.scale100,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale100,
        }),
        searchIcon: css({
            marginRight: theme.sizing.scale400,
        }),
        searchText: css({
            fontSize: "0.9rem",
            fontWeight: "600",
        }),
        searchPlaceholder: css({
            fontSize: "0.9rem",
            fontWeight: "600",
            color: theme.colors.contentTertiary,
        }),
    }
}
