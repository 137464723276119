import React, { type PropsWithChildren } from "react"
import { styled } from "baseui"

const StyledLink = styled<"a", { $colored: boolean; $underline: boolean }>(
    "a",
    ({ $theme, $colored, $underline }) => ({
        fontWeight: 600,
        color: $colored ? "#ff6c0d" : $theme.colors.contentPrimary,
        textDecoration: $underline ? "underline" : "none",
    })
)

interface Props extends PropsWithChildren {
    readonly to: string
    readonly colored?: boolean
    readonly underline?: boolean
    readonly id?: string
}

export default function ExternalLink({
    to,
    colored,
    underline,
    children,
    id,
}: Props) {
    return (
        <StyledLink
            id={id}
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            $colored={!!colored}
            $underline={!!underline}
        >
            {children}
        </StyledLink>
    )
}
