import React from "react"
import { navigate } from "gatsby-link"
import { useStyletron } from "baseui"
import { formatDistanceStrict } from "date-fns"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"

import type {
    ListingOutfitterFieldsFragment,
    PublicOutfitterFieldsFragment,
} from "~graphql/generated/graphql"

interface Props {
    readonly outfitter:
        | ListingOutfitterFieldsFragment
        | PublicOutfitterFieldsFragment
}

export default function OutfitterPopoverContent({ outfitter }: Props) {
    const styles = useStyles()

    return (
        <div className={styles.root}>
            {outfitter.avatar?.url && (
                <img src={outfitter.avatar?.url} className={styles.image} />
            )}
            <div className={styles.name}>{outfitter.name}</div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div
                        className={styles.location}
                    >{`${outfitter.location?.city}, ${outfitter.location?.state}`}</div>
                    <div className={styles.date}>
                        {`Joined ${formatDistanceStrict(
                            outfitter.created_at,
                            Date.now(),
                            { addSuffix: true }
                        )}`}
                    </div>
                </div>
                <Button
                    kind={KIND.primary}
                    size={SIZE.compact}
                    shape={SHAPE.pill}
                    onClick={() => navigate(`/${outfitter.slug}`)}
                >
                    Visit
                </Button>
            </div>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            width: "250px",
            backgroundColor: theme.colors.backgroundSecondary,
            paddingTop: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale600,
            paddingLeft: theme.sizing.scale600,
        }),
        image: css({
            width: "56px",
            height: "56px",
            objectFit: "cover",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            backgroundColor: "white",
            marginBottom: theme.sizing.scale400,
        }),
        name: css({
            fontWeight: "bold",
            marginBottom: theme.sizing.scale200,
        }),
        location: css({
            fontSize: "14px",
            marginBottom: theme.sizing.scale200,
        }),
        container: css({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        }),
        content: css({
            marginRight: theme.sizing.scale800,
        }),
        date: css({
            fontSize: "12px",
            color: theme.colors.contentTertiary,
        }),
    }
}
