import { isAfter, addDays } from "date-fns"
import filter from "lodash/filter"
import replace from "lodash/replace"
import type { StyleObject } from "styletron-standard"
import Big from "big.js"
import { isPossiblePhoneNumber } from "libphonenumber-js"

import type {
    CityAndStateFieldsFragment,
    PricingPackageFieldsFragment,
} from "~graphql/generated/graphql"
import {
    ListingType,
    PriceByGuest,
    PriceByDay,
} from "~graphql/generated/graphql"
import { LISTING_IMAGE_PLACEHOLDER } from "~config/constants"

export function formatPhoneNumber(phoneNumber: string): string {
    const cleaned = `${phoneNumber}`.replace(/\D/g, "")
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    }
    return phoneNumber
}

export function formatPrice(
    price: number | null | undefined,
    hideZeroCents: boolean = true
) {
    if (typeof price !== "number") return "N/A"

    const formattedPrice = `$${centsToDollars(price).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`
    return hideZeroCents ? replace(formattedPrice, ".00", "") : formattedPrice
}

export function formatPriceWithCents(
    price: number,
    hideZeroCents: boolean = true
) {
    const formattedPrice = `$${centsToDollars(price).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`
    return hideZeroCents ? replace(formattedPrice, ".00", "") : formattedPrice
}

export function centsToDollars(cents: number): number {
    return divide(cents, 100)
}

export function dollarsToCents(dollars: number): number {
    const cents = multiply(dollars, 100)

    return Math.round(cents)
}

export function add(num1: number, num2: number): number {
    return Big(num1).add(num2).toNumber()
}

export function multiply(num1: number, num2: number): number {
    return Big(num1).mul(num2).toNumber()
}

export function divide(num1: number, num2: number): number {
    return Big(num1).div(num2).toNumber()
}

export function takePercentage(total: number, percent: number): number {
    return Big(total).mul(percent).div(100).toNumber()
}

export function validatePhoneNumber(phone: string): boolean {
    const hasCountryCode = phone.startsWith("+")

    return hasCountryCode && isPossiblePhoneNumber(phone)
}

export function getListingTypeCamelcase(
    type: ListingType | null | undefined = ListingType.Hunting
) {
    switch (type) {
        case ListingType.Hunting:
            return "Hunting"
        case ListingType.Fishing:
            return "Fishing"
        case ListingType.BlastCast:
            return "Cast and Blast"
        default:
            return "Other"
    }
}

export function getCleanEnum(dirty: string): string {
    return dirty
        ? dirty
              .replace(/_/g, " ")
              .split(" ")
              .map((str) => str.charAt(0) + str.slice(1).toLowerCase())
              .join(" ")
        : ""
}

export function getShortLocation(location?: CityAndStateFieldsFragment | null) {
    if (!location) return null

    return `${location.city}, ${location.state}`
}

// Filters out dates before minDate
export function getAvailableListingDates({
    includeDates,
    minDate,
}: {
    includeDates: Date[]
    minDate: Date
}) {
    if (!minDate) return includeDates

    return filter(includeDates, (date) => isAfter(date, minDate))
}

// Returns an array of arrays (of length boolChecks.length + 1)
// split by the consecutive filters (boolChecks) passed in
// The last array is the items that don't satisfy any of the filters
// Ex. splitArrayWithFilters([1,2,3,4,5], [i => i < 3, i => i > 3]) => [[1,2],[4,5],[3]]
export const splitArrayWithFilters = <T>(
    array: T[],
    boolChecks: ((item: T) => boolean)[]
): T[][] => {
    const retArr: T[][] = [...boolChecks.map(() => []), []]

    array.forEach((item) => {
        const index = boolChecks.findIndex((check) => check(item))
        if (index === -1) {
            retArr[retArr.length - 1].push(item)
        } else {
            retArr[index].push(item)
        }
    })

    return retArr
}

// Conditional styletron
export const conditionalCss = (
    css: StyleObject,
    shouldApply?: boolean
): StyleObject => (shouldApply ? css : {})

// Cleaner string logic, if string exists, return it with optional pretext and posttext
export const stringIfExists = (
    conditionalString: string | null | undefined,
    options?: { pretext?: string; posttext?: string }
): string => {
    const { pretext, posttext } = options || {}

    return conditionalString
        ? `${pretext || ""}${conditionalString}${posttext || ""}`
        : ""
}

export const getUTCNoon = (date: Date | number): Date => {
    const tempDate: Date = new Date(date)

    return new Date(
        Date.UTC(
            tempDate.getFullYear(),
            tempDate.getMonth(),
            tempDate.getDate(),
            12,
            0,
            0
        )
    )
}

// Not using user fragment to allow partial user objects.
export function getFullName(
    user:
        | { first_name?: string | null; last_name?: string | null }
        | null
        | undefined
) {
    if (!user?.first_name || !user?.last_name) return ""

    return `${user.first_name} ${user.last_name}`
}

export function formatCityAndState(
    location: { city: string; state: string } | null | undefined
) {
    const city = location?.city || "No city yet"
    const state = location?.state || "No state yet"

    return `${city}, ${state}`
}

export function getPricingPackageEndDate(
    startDate: Date,
    daysIncluded: number
): Date {
    return addDays(startDate, daysIncluded - 1)
}

export function getArrayOfCount(length: number): number[] {
    return Array.from({ length })
        .fill(null)
        .map((_, index) => index)
}

export function isServerSide() {
    return typeof window === "undefined"
}

export function scrollToElementWithId(
    id: string,
    enableSmothScroll = false,
    position: ScrollLogicalPosition = "start"
) {
    const element = document.getElementById(id)
    if (element) {
        // Smooth scroll causes performance issues.
        element.scrollIntoView({
            behavior: enableSmothScroll ? "smooth" : "auto",
            block: position,
        })
    }
}

export function isString(value: unknown): value is string {
    return typeof value === "string"
}

export function isNil(value: unknown): value is null | undefined {
    return value === null || value === undefined
}

export function isSetGuestPricingPackage(
    pricingPackage: PricingPackageFieldsFragment | null | undefined
) {
    return pricingPackage?.price_by_guest === PriceByGuest.SetGuest
}

export function isSetDayPricingPackage(
    pricingPackage: PricingPackageFieldsFragment | null | undefined
) {
    return pricingPackage?.price_by_day === PriceByDay.SetDay
}

export function groupByKey<TItem>(array: TItem[], key: keyof TItem) {
    return array.reduce(
        (groupedObject, currentItem) => {
            const currentKey = currentItem[key] as string

            if (!groupedObject[currentKey]) {
                groupedObject[currentKey] = []
            }

            groupedObject[currentKey].push(currentItem)

            return groupedObject
        },
        {} as Record<string, TItem[]>
    )
}

export function getFirstPhotoUrl(photos: { url: string }[] | null | undefined) {
    return photos?.[0]?.url ?? LISTING_IMAGE_PLACEHOLDER
}

export async function getAndOpenPdfInNewTab({
    getPdfFunc,
}: {
    getPdfFunc: () => Promise<string | null>
}) {
    const pdf = await getPdfFunc()

    if (!pdf) return

    openPdfInNewTab(pdf)
}

function openPdfInNewTab(base64: string) {
    const buffer = Buffer.from(base64, "base64")
    const blob = new Blob([buffer], { type: "application/pdf" })
    const objectUrl = URL.createObjectURL(blob)

    // There's another method using window.open, but we need to use
    // the anchor element trick instead to avoid safari detecting this
    // as a popup and blocking it.
    const anchorElement = document.createElement("a")
    anchorElement.href = objectUrl
    anchorElement.target = "_blank"
    anchorElement.click()
}

export function parseInteger(val: string) {
    return parseInt(val, 10)
}

export function sortContentfulSectionsByOrder(
    contentfulSections: readonly Queries.MbContentfulPageSectionFieldsFragment[]
) {
    // sort by order from contentful
    return [...contentfulSections].sort(
        (firstSection, secondSection) =>
            firstSection.section_order - secondSection.section_order
    )
}
