import React, { type PropsWithChildren } from "react"
import { FormControl as BasewebFormControl } from "baseui/form-control"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly caption?: string
}

export default function FormControl({ label, caption, children }: Props) {
    return (
        <BasewebFormControl
            label={label}
            caption={caption}
            overrides={{
                Label: { style: { fontWeight: 700 } }, // TODO: move to theme
            }}
        >
            {children}
        </BasewebFormControl>
    )
}
