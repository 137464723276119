import React, { useState, useContext, useEffect } from "react"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { RiHeartLine, RiHeartFill } from "react-icons/ri"
import { useStyletron } from "baseui"

import { UserContext } from "~config/user-provider"
import WishlistModal from "~components/pages/wishlist/wishlist-modal"
import type { WishlistFieldsFragment } from "~graphql/generated/graphql"
import {
    useWishlistsByUserLazyQuery,
    useUpdateWishlistMutation,
} from "~graphql/generated/graphql"
import { useAuth } from "~utils/auth-hooks"
import useSnackbarWithAction from "~utils/use-snackbar-with-action"
import { getWishlistRoute } from "~utils/navigation-helpers"
import COPY from "~config/copy-constants"
import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly listingId: string
    readonly isSaved?: boolean
    readonly isHeader?: boolean
    readonly isInTitle?: boolean
}

export default function WishlistButton({
    listingId,
    isSaved: initialSaved,
    isHeader,
    isInTitle,
}: Props) {
    const { isAuthenticated, loginWithRedirect, accessToken } = useAuth()

    const [isOpen, setIsOpen] = useState(false)
    const [isSaved, setIsSaved] = useState(initialSaved || false)
    const [listIndex, setListIndex] = useState(-1)
    const [isCreating, setIsCreating] = useState(false)
    const [wishlists, setWishlists] = useState<WishlistFieldsFragment[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const { user, isFetching } = useContext(UserContext)

    const styles = useStyles()

    const showSnackbar = useRemoveFromWishlistSnackbar({
        wishlists,
        listIndex,
    })

    const getNewListingsArray = () => {
        if (listIndex < 0) return []
        const listingArray = [
            ...wishlists[listIndex].listings.map((listing) => listing.id),
        ]
        listingArray.splice(listingArray.indexOf(listingId), 1)
        return listingArray
    }

    const [wishlistsForUser, { data }] = useWishlistsByUserLazyQuery({
        context: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
        variables: { userId: user ? user.id : "" },
        onCompleted: () => setIsCreating(false),
    })

    const [updateWishlist, { loading: isUpdating }] = useUpdateWishlistMutation(
        {
            context: {
                headers: accessToken
                    ? { authorization: `Bearer ${accessToken}` }
                    : {},
            },
            variables: {
                id: listIndex > -1 ? wishlists[listIndex].id : "",
                data: {
                    listings: getNewListingsArray(),
                },
            },
            // This initial state is to fix a bug with refetching queries, I'm sure there is a cleaner
            // solution but this will do for now.
            onCompleted: () => setIsSaved(initialSaved || false),
        }
    )

    // TODO do not use useeffect here
    useEffect(() => {
        if (user && user.id) void wishlistsForUser()
    }, [user, wishlistsForUser])

    useEffect(() => {
        if (data) {
            setListIndex(-1)
            const { wishlistsByUser } = data
            setWishlists(wishlistsByUser)
        }
    }, [data])

    // Check listings of favorite lists until listingId is found, then return false to break every().
    useEffect(() => {
        if (wishlists.length === 0) {
            setIsLoading(false)
        } else {
            wishlists.every((wishlist, index) => {
                if (
                    wishlist.listings.some(
                        (listing) => listing.id === listingId
                    )
                ) {
                    setIsSaved(true)
                    setListIndex(index)
                    setIsLoading(false)
                    return false
                }
                if (index === wishlists.length - 1) {
                    setIsLoading(false)
                }
                return true
            })
        }
    }, [wishlists])

    const handleButtonClick = (
        event: React.SyntheticEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation()
        event.preventDefault()

        if (isSaved) {
            showSnackbar()
            void updateWishlist()
        } else if (isAuthenticated) {
            setIsOpen(true)
        } else {
            void loginWithRedirect()
        }
    }

    return (
        <>
            {isInTitle ? (
                <Button
                    data-testid={TEST_IDS.WISHLIST_BUTTON}
                    startEnhancer={
                        isSaved ? (
                            <RiHeartFill className={styles.iconFill} />
                        ) : (
                            <RiHeartLine className={styles.iconLine} />
                        )
                    }
                    isLoading={isUpdating || isFetching || isLoading}
                    shape={SHAPE.pill}
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    overrides={{
                        BaseButton: { style: { marginLeft: "8px" } },
                    }}
                    onClick={handleButtonClick}
                >
                    {isSaved ? "Saved" : "Save"}
                </Button>
            ) : (
                <Button
                    data-testid={TEST_IDS.WISHLIST_BUTTON}
                    className={isHeader ? styles.button : undefined}
                    shape={SHAPE.circle}
                    kind={KIND.secondary}
                    size={SIZE.compact}
                    onClick={handleButtonClick}
                    isLoading={isUpdating || isFetching || isLoading}
                >
                    {isSaved ? (
                        <RiHeartFill className={styles.iconFill} />
                    ) : (
                        <RiHeartLine className={styles.iconLine} />
                    )}
                </Button>
            )}
            {user && (
                <WishlistModal
                    listingId={listingId}
                    wishlists={wishlists}
                    user={user}
                    isFetching={isFetching}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                />
            )}
        </>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        button: css({
            boxShadow: theme.lighting.shadow500,
            fontSize: "16px",
            marginLeft: theme.sizing.scale600,
        }),
        iconFill: css({
            fontSize: "20px",
            color: theme.colors.contentNegative,
        }),
        iconLine: css({
            fontSize: "20px",
        }),
    }
}

function useRemoveFromWishlistSnackbar({
    wishlists,
    listIndex,
}: {
    wishlists: WishlistFieldsFragment[]
    listIndex: number
}) {
    const { showSnackbarWithAction } = useSnackbarWithAction({
        message: `${COPY.REMOVED_FROM} ${wishlists[listIndex]?.name}`,
        actionLabel: COPY.VIEW_LIST,
        actionRoute: getWishlistRoute(wishlists[listIndex]?.id),
        Icon: RiHeartLine,
    })

    return showSnackbarWithAction
}
