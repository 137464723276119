import React, { useState, useRef, useEffect, useMemo } from "react"
import type { ImgHTMLAttributes } from "react"

import { useTheme } from "~config/theme"
import { resizeUrl } from "~utils/upload-care-helpers"
import LoadingSkeleton from "~components/shared/loading-skeleton"
import LazyContainer from "~components/shared/lazy-container"
import { LISTING_IMAGE_PLACEHOLDER } from "~config/constants"

const UPLOAD_CARE_BASE_DOMAIN = "ucarecdn.com"

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    // Require height and width to improve content shift and support lazy loading
    // TODO Do we need the dimensions? Seems we can use them as a starting point, then override
    // with css, but is this really helping? It's a bit of a hastle to have to pass them
    readonly width: string
    readonly height: string
    readonly onLoad?: () => void
}

export default function Image({ src, className, onLoad, ...props }: Props) {
    const imgRef = useRef<HTMLImageElement>(null)

    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const styles = useStyles({ isImageLoaded })
    const isUploadCareImage = useMemo(
        () => src?.includes(UPLOAD_CARE_BASE_DOMAIN),
        [src]
    )

    const srcWithPlaceholder = src ?? LISTING_IMAGE_PLACEHOLDER
    const resizedSrc = isUploadCareImage
        ? resizeUrl(srcWithPlaceholder)
        : srcWithPlaceholder
    const classNameWithStyles = `${styles.img} ${className ?? ""}`
    const onLoadWrapped = () => {
        setIsImageLoaded(true)

        if (onLoad) onLoad()
    }

    // https://stackoverflow.com/a/59153135
    useEffect(() => {
        if (imgRef.current?.complete && !isImageLoaded) {
            onLoadWrapped()
        }
    }, [src])

    return (
        <LazyContainer>
            {!isImageLoaded && <LoadingSkeleton />}

            <img
                ref={imgRef}
                src={resizedSrc}
                onLoad={() => {
                    onLoadWrapped()
                }}
                className={classNameWithStyles}
                {...props}
            />
        </LazyContainer>
    )
}

function useStyles({ isImageLoaded }: { isImageLoaded: boolean }) {
    const { css } = useTheme()

    return {
        img: css({
            display: isImageLoaded ? "initial" : "none",
        }),
    }
}
