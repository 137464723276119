import React from "react"
import type { PopoverProps, PopoverOverrides } from "baseui/popover"
import { Popover as PopoverOg } from "baseui/popover"

import { useTheme } from "~config/theme"

export default function Popover(props: PopoverProps) {
    const popoverOverrides = usePopoverOverrides()

    return <PopoverOg overrides={popoverOverrides} {...props} />
}

function usePopoverOverrides(): PopoverOverrides {
    const { theme } = useTheme()

    return {
        Body: { style: { zIndex: theme.zIndex.popover } },
    }
}
