import { styled } from "baseui"

// use this component to provide a onClick handler to a element that should behave like a div
const UnstyledButton = styled("button", {
    textAlign: "inherit",
    background: "none",
    color: "inherit",
    font: "inherit",
    outline: "none",
    border: "none",
    cursor: "pointer",
    padding: 0,
    ":disabled": {
        cursor: "default",
    },
})

export default UnstyledButton
