import React, { useState } from "react"
import { useStyletron } from "baseui"
import { useSpring, animated } from "react-spring"

import SearchInput from "~components/shared/search-input/search-input"
import HeaderSearchButton from "~components/shared/header/header-search-button"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"
import { getUrlQueryParam } from "~utils/url-param-helpers"
import { TEST_IDS } from "~config/test-ids"

interface SearchBarProps {
    readonly isCollapsed?: boolean
    readonly clickHandler: () => void
}

export default function HeaderSearchBar({
    isCollapsed = false,
    clickHandler,
}: SearchBarProps) {
    const state = getUrlQueryParam("state")
    const species = getUrlQueryParam("species")
    const query = getUrlQueryParam("query")
    const startDate = getUrlQueryParam("startDate")
    const endDate = getUrlQueryParam("endDate")

    const [dates, setDates] = useState<Date[]>(
        getPrefilledDates(startDate, endDate)
    )
    const styles = useStyles()

    const collapsedStyles = useSpring({
        opacity: isCollapsed ? 1 : 0,
        config: { duration: 200 },
    })

    const expandedStyles = useSpring({
        opacity: isCollapsed ? 0 : 1,
        config: { duration: 200 },
    })

    return (
        <>
            <ConditionalRenderContainer shouldRender={isCollapsed}>
                <animated.div style={collapsedStyles}>
                    <HeaderSearchButton handleClick={clickHandler} />
                </animated.div>
            </ConditionalRenderContainer>
            <ConditionalRenderContainer shouldRender={!isCollapsed}>
                <animated.div style={expandedStyles}>
                    <div
                        className={styles.searchContainer}
                        data-testid={TEST_IDS.HEADER_SEARCH_BAR}
                    >
                        <div className={styles.inputWrapper}>
                            <SearchInput
                                query={query || species || state || ""}
                                dates={dates}
                                setDates={setDates}
                            />
                        </div>
                    </div>
                </animated.div>
            </ConditionalRenderContainer>
        </>
    )
}

type QueryParamDate = string | (string | null)[] | null

function getPrefilledDates(startDate: QueryParamDate, endDate: QueryParamDate) {
    if (startDate && endDate) {
        return [new Date(Number(startDate)), new Date(Number(endDate))]
    }

    return []
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        searchContainer: css({
            width: "100%",
            maxWidth: "750px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }),
        inputWrapper: css({
            width: "100%",
            marginTop: "12px",
            marginBottom: "12px",
            [theme.mediaQuery.medium]: {
                marginTop: 0,
                marginBottom: 0,
            },
        }),
    }
}
