import React from "react"
import { ArrowRight } from "baseui/icon"
import { Button, KIND, SHAPE } from "baseui/button"
import type { ButtonOverrides } from "baseui/button"

import { useTheme } from "~config/theme"

interface Props {
    readonly label?: string | null
    readonly href?: string | null
}

export default function CollectionButton({ label, href }: Props) {
    const overrides = useButtonOverrides()

    if (!label || !href) return null

    return (
        <Button
            $as="a"
            href={href}
            target="_blank"
            kind={KIND.tertiary}
            shape={SHAPE.pill}
            endEnhancer={<ArrowRight size={24} />}
            overrides={overrides}
        >
            {label}
        </Button>
    )
}

function useButtonOverrides(): ButtonOverrides {
    const { theme } = useTheme()

    return {
        BaseButton: {
            style: () => {
                const color = theme.colors.primaryOrange
                const borderWidth = "1.5px"
                const borderStyle = "solid"

                return {
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    borderTopWidth: borderWidth,
                    borderTopStyle: borderStyle,
                    borderTopColor: color,
                    borderRightWidth: borderWidth,
                    borderRightStyle: borderStyle,
                    borderRightColor: color,
                    borderBottomWidth: borderWidth,
                    borderBottomStyle: borderStyle,
                    borderBottomColor: color,
                    borderLeftWidth: borderWidth,
                    borderLeftStyle: borderStyle,
                    borderLeftColor: color,
                    color: color,
                    fontWeight: 600,
                }
            },
        },
        EndEnhancer: {
            style: { marginLeft: "8px" },
        },
    }
}
