import React from "react"
import { useStyletron } from "baseui"
import { PLACEMENT } from "baseui/popover"

import UserAvatar from "~components/shared/user-avatar"
import LoadingSkeleton from "~components/shared/loading-skeleton"
import ProfileButtonMenu from "~components/shared/header/header-profile-button-menu"
import { popoverOverrides } from "~components/shared/overrides"
import StatefulPopover from "~components/shared/stateful-popover"
import { useUser } from "~config/user-provider"

export default function HeaderProfileButton() {
    const styles = useStyles()
    const { user, isFetching } = useUser()

    const firstName = user?.first_name ?? "Menu"

    return (
        <div>
            <StatefulPopover
                autoFocus
                focusLock
                placement={PLACEMENT.bottomRight}
                overrides={popoverOverrides}
                renderContent={({ close }) => (
                    <ProfileButtonMenu close={close} />
                )}
            >
                <button type="button" className={styles.button}>
                    {isFetching ? (
                        <LoadingSkeleton width="50px" height="16px" />
                    ) : (
                        firstName
                    )}
                    <UserAvatar />
                </button>
            </StatefulPopover>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        button: css({
            position: "relative",
            paddingTop: "4px",
            paddingRight: "4px",
            paddingBottom: "4px",
            paddingLeft: "16px",
            backgroundColor: theme.colors.backgroundPrimary,
            borderRadius: "22px",
            fontWeight: 600,
            fontSize: "15px",
            color: theme.colors.contentPrimary,
            textDecoration: "none",
            boxShadow: theme.lighting.shadow400,
            border: `1px solid ${theme.colors.borderOpaque}`,
            transition: "all 0.25s",
            lineHeight: "18px",
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            ":hover": {
                boxShadow: theme.lighting.shadow500,
                cursor: "pointer",
            },
        }),
    }
}
