import React from "react"
import { Button, KIND } from "baseui/button"
import { ButtonGroup, SHAPE, SIZE } from "baseui/button-group"
import { ChevronLeft, ChevronRight } from "baseui/icon"

import {
    useTabletMediaQueries,
    useMobileMediaQueries,
} from "~utils/use-media-queries"

const ICON_SIZE = 26
const KEY_LEFT = "KEY_LEFT"
const KEY_RIGHT = "KEY_RIGHT"

interface Props {
    readonly onLeftClick: () => void
    readonly onRightClick: () => void
}

export default function ResponsiveSliderButtons({
    onLeftClick,
    onRightClick,
}: Props) {
    const { isTabletOnly } = useTabletMediaQueries()
    const { isMobileOnly } = useMobileMediaQueries()

    // Do not show for touch devices either
    if (isTabletOnly || isMobileOnly) return null

    const buttonConfigs = getButtonConfigs({ onLeftClick, onRightClick })

    return (
        <ButtonGroup size={SIZE.compact} shape={SHAPE.circle}>
            {buttonConfigs.map(({ key, ...config }) => (
                <Button key={key} {...config} />
            ))}
        </ButtonGroup>
    )
}

function getButtonConfigs({ onLeftClick, onRightClick }: Props) {
    return [
        {
            key: KEY_LEFT,
            kind: KIND.tertiary,
            onClick: onLeftClick,
            children: <ChevronLeft size={ICON_SIZE} />,
        },
        {
            key: KEY_RIGHT,
            kind: KIND.tertiary,
            onClick: onRightClick,
            children: <ChevronRight size={ICON_SIZE} />,
        },
    ]
}
