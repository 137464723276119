import React from "react"

import {
    useListingsQuery,
    useListingsByStateQuery,
    useFeaturedListingsQuery,
    useSearchListingsQuery,
} from "~graphql/generated/graphql"
import type { Props as CoreProps } from "~components/shared/collections/listings-collection"
import ListingsCollection from "~components/shared/collections/listings-collection"
import { getSearchDataFromUrlQueryString } from "~utils/url-param-helpers"

type ListingQueryType =
    (typeof LISTING_QUERY_TYPES)[keyof typeof LISTING_QUERY_TYPES]

export const DEFAULT_QUERY_SIZE = 20
export const LISTING_QUERY_TYPES = {
    SEARCH: "SEARCH",
    ID_LIST: "ID_LIST",
    STATE: "STATE",
    FEATURED: "FEATURED",
} as const

export interface Props extends CoreProps {
    readonly idList?: string[]
    readonly searchString?: string
    readonly state?: string
    readonly queryType: ListingQueryType
}

export default function ListingsCollectionQueryable(props: Props) {
    const { listings, isLoading } = useQueryListings(props)

    return (
        <ListingsCollection
            {...props}
            listings={listings}
            isLoading={isLoading}
        />
    )
}

function useQueryListings(props: Props) {
    const { queryType, idList, searchString, state } = props
    // Search
    const searchParams = getSearchDataFromUrlQueryString(String(searchString))

    const {
        loading: loadingFromSearch,
        error: errorFromSearch,
        data: dataFromSearch,
    } = useSearchListingsQuery({
        variables: { data: searchParams, from: 0 },
        skip: queryType !== LISTING_QUERY_TYPES.SEARCH,
    })
    const listingsFromSearch = dataFromSearch?.listings?.results || []

    // Id list
    const {
        loading: loadingFromIdList,
        error: errorFromIdList,
        data: dataFromIdList,
    } = useListingsQuery({
        variables: { ids: idList ?? [] },
        skip: queryType !== LISTING_QUERY_TYPES.ID_LIST,
    })
    const listingsFromIdList = dataFromIdList?.listings || []

    // State
    const {
        loading: loadingFromState,
        error: errorFromState,
        data: dataFromState,
    } = useListingsByStateQuery({
        variables: { state: state ? String(state) : "" },
        skip: queryType !== LISTING_QUERY_TYPES.STATE,
    })
    const listingsFromState = dataFromState?.listingsByState || []

    // Featured
    const {
        loading: loadingFromFeatured,
        error: errorFromFeatured,
        data: dataFromFeatured,
    } = useFeaturedListingsQuery({
        variables: {
            size: DEFAULT_QUERY_SIZE,
        },
        skip: queryType !== LISTING_QUERY_TYPES.FEATURED,
    })
    const listingsFromFeatured = dataFromFeatured?.featuredListings || []

    return {
        isLoading:
            loadingFromIdList ||
            loadingFromSearch ||
            loadingFromState ||
            loadingFromFeatured,
        error:
            errorFromIdList ||
            errorFromSearch ||
            errorFromState ||
            errorFromFeatured,
        listings: [
            ...listingsFromIdList,
            ...listingsFromSearch,
            ...listingsFromState,
            ...listingsFromFeatured,
        ],
    }
}
