import React from "react"
import { Button, KIND, SHAPE } from "baseui/button"
import { ArrowLeft, Delete } from "baseui/icon"
import { useStyletron } from "baseui"

import { TEST_IDS } from "../../../../config/test-ids"

interface Props {
    readonly isSearching: boolean
    readonly onClick: () => void
}

export default function BackButton({ isSearching, onClick }: Props) {
    const styles = useStyles()
    return (
        <div className={styles.header}>
            <Button
                shape={SHAPE.circle}
                kind={KIND.tertiary}
                onClick={onClick}
                overrides={getButtonOverrides()}
            >
                {isSearching ? <ArrowLeft size={24} /> : <Delete size={24} />}
            </Button>
        </div>
    )
}

function getButtonOverrides() {
    return {
        BaseButton: {
            props: {
                "data-testid": TEST_IDS.MOBILE_SEARCH_BACK_BUTTON,
            },
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        header: css({
            paddingTop: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale400,
            paddingRight: theme.sizing.scale400,
        }),
    }
}
