import { navigate } from "gatsby-link"

export function handleSearch(value: string, dates: Date[]) {
    void navigate(convertParamsToQueryString(value, dates))
}

export function convertParamsToQueryString(query: string, dates: Date[] = []) {
    if (query !== "" || dates.length >= 2) {
        const dateSearchParams =
            dates.length > 1
                ? `&startDate=${dates[0].getTime()}&endDate=${dates[1].getTime()}`
                : ""

        return `/search?query=${query}${dateSearchParams}`
    }

    return ""
}
