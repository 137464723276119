import React, { useContext } from "react"
import { Avatar } from "baseui/avatar"

import LoadingSkeleton from "~components/shared/loading-skeleton"
import { UserContext } from "~config/user-provider"
import { useTheme } from "~config/theme"
import { getFullName } from "~utils/helpers"

interface Props {
    readonly size?: string
}

export default function UserAvatar({ size = "32px" }: Props) {
    const { user, isFetching, fetchError } = useContext(UserContext)

    const styles = useStyles({ size, hasError: Boolean(fetchError) })

    return (
        <div className={styles.avatar}>
            {isFetching ? (
                <LoadingSkeleton circle />
            ) : (
                <Avatar
                    size={size}
                    src={user?.avatar_url}
                    name={getFullName(user)}
                />
            )}
        </div>
    )
}

function useStyles({ size, hasError }: { size: string; hasError: boolean }) {
    const { css } = useTheme()

    return {
        avatar: css({
            width: size,
            height: size,
            marginLeft: "10px",
            // Hides the avatar on error but still takes up space to keep layout.
            visibility: hasError ? "hidden" : "visible",
        }),
    }
}
