import type { PopoverOverrides } from "baseui/popover"

import { Z_INDICES } from "~config/constants"

export const popoverOverrides: PopoverOverrides = {
    Body: {
        style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundPrimary,
            borderTopLeftRadius: $theme.borders.radius400,
            borderTopRightRadius: $theme.borders.radius400,
            borderBottomRightRadius: $theme.borders.radius400,
            borderBottomLeftRadius: $theme.borders.radius400,
            width: "264px",
            maxWidth: "80vw",
            zIndex: Z_INDICES.POPOVER,
        }),
    },
    Inner: {
        style: ({ $theme }) => ({
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopColor: $theme.colors.borderOpaque,
            borderRightColor: $theme.colors.borderOpaque,
            borderBottomColor: $theme.colors.borderOpaque,
            borderLeftColor: $theme.colors.borderOpaque,
            backgroundColor: $theme.colors.backgroundPrimary,
            borderTopLeftRadius: $theme.borders.radius400,
            borderTopRightRadius: $theme.borders.radius400,
            borderBottomRightRadius: $theme.borders.radius400,
            borderBottomLeftRadius: $theme.borders.radius400,
            paddingTop: $theme.sizing.scale300,
            paddingRight: 0,
            paddingBottom: $theme.sizing.scale300,
            paddingLeft: 0,
        }),
    },
}
