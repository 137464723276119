import React from "react"
import { useStyletron } from "baseui"
import { PLACEMENT, TRIGGER_TYPE } from "baseui/popover"

import type {
    ListingOutfitterFieldsFragment,
    PublicOutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import StatefulPopover from "~components/shared/stateful-popover"
import { TEST_IDS } from "~config/test-ids"

import OutfitterPopoverContent from "./outfitter-popover-content"

interface Props {
    readonly outfitter?:
        | ListingOutfitterFieldsFragment
        | PublicOutfitterFieldsFragment
    readonly namePrefix?: string
}

export default function OutfitterPopover({
    outfitter,
    namePrefix = "",
}: Props) {
    const styles = useStyles()
    if (!outfitter) return null

    return (
        <StatefulPopover
            renderContent={() => (
                <OutfitterPopoverContent outfitter={outfitter} />
            )}
            overrides={getPopOverOverrides()}
            placement={PLACEMENT.bottomLeft}
            triggerType={TRIGGER_TYPE.hover}
            showArrow
            returnFocus
            autoFocus
        >
            <div
                className={styles.outfitter}
                data-testid={TEST_IDS.OUTFITTER_POPOVER}
            >
                {namePrefix}
                <span className={styles.outfitterName}>{outfitter.name}</span>
            </div>
        </StatefulPopover>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            position: "relative",
        }),
        image: css({
            width: "100%",
            height: "100%",
            objectFit: "cover",
        }),
        singleImage: css({
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderTopLeftRadius: theme.borders.radius400,
            borderTopRightRadius: theme.borders.radius400,
            borderBottomRightRadius: theme.borders.radius400,
            borderBottomLeftRadius: theme.borders.radius400,
        }),
        wishlistButton: css({
            position: "absolute",
            top: "13px",
            right: "13px",
        }),
        info: css({
            marginTop: theme.sizing.scale500,
        }),
        subtitle: css({
            fontSize: "14px",
            fontWeight: 300,
            color: theme.colors.contentTertiary,
        }),
        title: css({
            fontWeight: 600,
            fontSize: "18px",
            marginTop: theme.sizing.scale100,
            marginBottom: theme.sizing.scale100,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "capitalize",
        }),
        outfitter: css({
            fontSize: "14px",
            width: "fit-content",
            color: theme.colors.linkText,
        }),
        outfitterName: css({
            textDecoration: "underline",
            textDecorationStyle: "dotted",
        }),
    }
}

function getPopOverOverrides() {
    return {
        Body: {
            style: () => ({
                borderRadius: "12px",
                overflow: "hidden",
            }),
        },
    }
}
