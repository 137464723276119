import React from "react"
import { useStyletron } from "baseui"

import { useAuth } from "~utils/auth-hooks"
import COPY from "~config/copy-constants"

export default function HeaderLoginButton() {
    const styles = useStyles()
    const { loginWithRedirect } = useAuth()

    return (
        <div className={styles.button} onClick={loginWithRedirect}>
            {COPY.SIGN_UP_OR_LOG_IN}
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        button: css({
            position: "relative",
            paddingTop: "8px",
            paddingRight: "16px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopColor: theme.colors.borderOpaque,
            borderRightColor: theme.colors.borderOpaque,
            borderBottomColor: theme.colors.borderOpaque,
            borderLeftColor: theme.colors.borderOpaque,
            backgroundColor: theme.colors.backgroundPrimary,
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
            borderBottomRightRadius: "22px",
            borderBottomLeftRadius: "22px",
            fontWeight: 600,
            fontSize: "15px",
            color: theme.colors.contentPrimary,
            textDecoration: "none",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.18)",
            transition: "all 0.25s",
            lineHeight: "18px",
            display: "inline-block",
            ":hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.18)",
                cursor: "pointer",
            },
        }),
    }
}
