import queryString, { type ParsedQuery } from "query-string"

import { QUERY_STRING_PROPS, UNKNOWN_ID } from "~config/constants"
import { isServerSide, parseInteger, dollarsToCents } from "~utils/helpers"
import { BookingInternalSource } from "~graphql/generated/graphql"
import type { SearchListingsInput } from "~graphql/generated/graphql"

import { removeUndefinedProps } from "./object-helpers"

const UNDEF_STRING_VALUE = "undefined"

export function getUrlQueryParamsObj() {
    if (isServerSide()) {
        return {}
    }
    const { search } = window.location
    return queryString.parse(search)
}

export function getUrlQueryParam(paramName: string) {
    const paramsObj = getUrlQueryParamsObj()
    const param = paramsObj[paramName]
    const isParamValid =
        typeof param === "string" && param !== UNDEF_STRING_VALUE

    return isParamValid ? param : null
}

export function getQuoteIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.QUOTE_ID)
}

export function getQuotePackageIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.QUOTE_PACKAGE_ID)
}

export function getPricingPackageIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.PACKAGE_ID)
}

export function getBookingInviteIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.BOOKING_INVITE_ID)
}

export function getUtmQueryParams() {
    const utmCampaign =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_CAMPAIGN) ?? undefined
    const utmContent =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_CONTENT) ?? undefined
    const utmMedium =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_MEDIUM) ?? undefined
    const utmSource =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_SOURCE) ?? undefined
    const utmTerm = getUrlQueryParam(QUERY_STRING_PROPS.UTM_TERM) ?? undefined

    return {
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        utmTerm,
    }
}

export function getInternalSourceQueryParams({
    defaultEntityId,
    defaultEntityType,
}: {
    defaultEntityId?: string
    defaultEntityType: string
}) {
    const internalSource = getUrlQueryParam(QUERY_STRING_PROPS.INTERNAL_SOURCE)
    const internalSourceEntityId = getUrlQueryParam(
        QUERY_STRING_PROPS.INTERNAL_SOURCE_ENTITY_ID
    )
    const internalSourceEntityType = getUrlQueryParam(
        QUERY_STRING_PROPS.INTERNAL_SOURCE_ENTITY_TYPE
    )

    // Only use the defaultEntityType if there is a defaultEntityId.
    // This will avoid accidentally setting the wrong entity type if for some reason it were missing even when the id is present
    const normalizedDefaultEntityType = defaultEntityId
        ? defaultEntityType
        : undefined

    const params = {
        // Default to an unprompted mallard bay booking (can also be from a widget or sent quote)
        internal_source: internalSource ?? BookingInternalSource.MallardBay,
        internal_source_entity_id:
            internalSourceEntityId ?? (defaultEntityId || UNKNOWN_ID),
        internal_source_entity_type:
            internalSourceEntityType ?? normalizedDefaultEntityType,
    }
    return removeUndefinedProps(params)
}

export function useSearchDataFromUrl() {
    const searchParams = getUrlQueryParamsObj()

    return mapSearchStringToSearchListingsInput(searchParams)
}

export function getSearchDataFromUrlQueryString(qString: string) {
    const params = queryString.parse(qString)

    return mapSearchStringToSearchListingsInput(params)
}

function mapSearchStringToSearchListingsInput(
    searchParams: ParsedQuery<string>
) {
    const {
        type,
        species,
        query,
        startDate,
        endDate,
        priceMin,
        priceMax,
        amenities,
    } = searchParams

    const saerchListingsInput: SearchListingsInput = {
        ...(type ? { type: String(type) } : null),
        ...(query ? { text: String(query) } : null),
        ...(startDate ? { startDate: parseInteger(String(startDate)) } : null),
        ...(endDate ? { endDate: parseInteger(String(endDate)) } : null),
        ...(species ? { species: parseList(species) } : null),
        ...(amenities ? { amenities: parseList(amenities) } : null),
        ...(priceMin ? { priceMin: parsePrice(String(priceMin)) } : null),
        ...(priceMax ? { priceMax: parsePrice(String(priceMax)) } : null),
    }

    return saerchListingsInput
}

function parsePrice(priceString: ParsedQueryPropType) {
    // eslint-disable-next-line no-param-reassign
    priceString = String(priceString)
    return priceString && !isNaN(parseInteger(priceString))
        ? dollarsToCents(parseInteger(priceString))
        : undefined
}

function parseList(listString?: ParsedQueryPropType) {
    if (!listString) return undefined
    return String(listString).split(",")
}

// Matches the definition in ParsedQuery
type ParsedQueryPropType = string | (string | null)[]
