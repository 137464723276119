import React from "react"
import type { PropsWithChildren } from "react"

import { useTheme } from "~config/theme"
import type { MediaQueryKey } from "~config/theme"

interface Props extends PropsWithChildren {
    readonly mediaQuery?: MediaQueryKey
}

export default function HiddenOnDesktopContainer({
    children,
    mediaQuery = "medium",
}: Props) {
    const styles = useStyles({ mediaQuery })

    return <div className={styles.root}>{children}</div>
}

function useStyles({ mediaQuery }: { mediaQuery: MediaQueryKey }) {
    const { css, theme } = useTheme()

    return {
        root: css({
            display: "block",

            [theme.mediaQuery[mediaQuery]]: {
                display: "none",
            },
        }),
    }
}
