import React from "react"

import type { Props as OutfittersQueryableProps } from "~components/shared/collections/outfitters-collection-queryable"
import OutfittersCollectionQueryable from "~components/shared/collections/outfitters-collection-queryable"
import ListingsCollectionQueryable from "~components/shared/collections/listings-collection-queryable"
import type { Props as ListingsQueryableProps } from "~components/shared/collections/listings-collection-queryable"

interface SharedBuilderProps {
    readonly idMaps?: { id: string }[]
}

export type OutfittersProps = OutfittersQueryableProps & SharedBuilderProps

export type ListingsProps = ListingsQueryableProps & SharedBuilderProps

export function BuilderioOutfittersCollection(props: OutfittersProps) {
    const mappedProps = mapBuilderioProps(props)

    return <OutfittersCollectionQueryable {...mappedProps} />
}

export function BuilderioListingsCollection(props: ListingsProps) {
    const mappedProps = mapBuilderioProps(props)

    return <ListingsCollectionQueryable {...mappedProps} />
}

function mapBuilderioProps<P extends SharedBuilderProps>(props: P) {
    const idList = (props.idMaps || [])?.map(({ id }) => id.toString())
    return {
        ...props,
        idList,
    }
}
