import React from "react"
import { RiSunLine, RiMoonClearFill } from "react-icons/ri"

import { useTheme } from "~config/theme"
import { ThemeStates, useThemeContext } from "~config/theme-provider"

export default function HeaderThemeButton() {
    const { theme, toggle } = useThemeContext()
    const styles = useStyles()

    return theme === ThemeStates.LIGHT ? (
        <RiMoonClearFill onClick={toggle} className={styles.icon} />
    ) : (
        <RiSunLine onClick={toggle} className={styles.icon} />
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        icon: css({
            fontSize: "21px",
            transition: "all .2s",
            marginRight: theme.sizing.scale600,
            color: theme.colors.contentPrimary,
            ":hover": {
                cursor: "pointer",
                color: theme.colors.contentTertiary,
            },
        }),
    }
}
