import React, { type PropsWithChildren } from "react"
import { useStyletron } from "baseui"
import type { IconType } from "react-icons"

import ExternalLink from "~components/shared/external-link"
import MenuButtonLabelAndIcon from "~components/shared/header/menu-button-label-and-icon"

export interface Props extends PropsWithChildren {
    readonly label?: string
    readonly onClick?: () => void
    readonly Icon?: IconType
    readonly isBold?: boolean
    readonly externalLinkUrl?: string
}
export default function HeaderProfileMenuButton({
    label,
    onClick,
    Icon,
    isBold,
    externalLinkUrl,
    children,
}: Props) {
    const styles = useStyles()

    if (externalLinkUrl) {
        return (
            <ExternalLink to={externalLinkUrl}>
                <div className={`${styles.button}`}>
                    <MenuButtonLabelAndIcon
                        label={label}
                        Icon={Icon}
                        isBold={isBold}
                    />
                </div>
            </ExternalLink>
        )
    }

    return (
        <button type="button" className={styles.button} onClick={onClick}>
            {children}
            <MenuButtonLabelAndIcon label={label} Icon={Icon} isBold={isBold} />
        </button>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        button: css({
            width: "100%",
            fontSize: "15px",
            fontWeight: 300,
            userSelect: "none",
            paddingTop: theme.sizing.scale400,
            paddingRight: theme.sizing.scale700,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale700,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "transparent",
            border: "none",
            textAlign: "left",
            boxSizing: "border-box",
            color: theme.colors.contentPrimary,
            ":hover": {
                cursor: "pointer",
                backgroundColor: theme.colors.backgroundSecondary,
            },
        }),
    }
}
