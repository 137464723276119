import React from "react"

import { useTheme } from "~config/theme"
import { SUPPORT_CONTACTS } from "~config/constants"
import ExternalLink from "~components/shared/external-link"

interface Props {
    readonly isPhone?: boolean
    readonly isEmail?: boolean
    readonly isBold?: boolean
}

export default function SupportLink({
    isPhone,
    isEmail,
    isBold = true,
}: Props) {
    const styles = useStyles({ isBold })

    if (!isEmail && !isPhone) return null

    const supportLink = getSupportLink(isPhone, isEmail)
    const supportLinkCopy = getSupportLinkCopy(isPhone, isEmail)

    return (
        <ExternalLink to={`${supportLink}`}>
            <p className={styles.text}>{supportLinkCopy}</p>
        </ExternalLink>
    )
}

function useStyles({ isBold }: { isBold?: boolean }) {
    const { css } = useTheme()

    return {
        text: css({
            fontWeight: isBold ? 700 : 400,
        }),
    }
}

function getSupportLink(isPhone?: boolean, isEmail?: boolean) {
    if (isPhone) {
        return `tel:${SUPPORT_CONTACTS.PHONE}`
    }
    if (isEmail) {
        return `mailto:${SUPPORT_CONTACTS.EMAIL}`
    }
    return ""
}

function getSupportLinkCopy(isPhone?: boolean, isEmail?: boolean) {
    if (isPhone) {
        return SUPPORT_CONTACTS.PHONE
    }
    if (isEmail) {
        return SUPPORT_CONTACTS.EMAIL
    }
    return ""
}
