import React, { useRef } from "react"
import type { RefObject } from "react"
import { useIntersectionObserver } from "usehooks-ts"

interface Props {
    readonly children: React.ReactNode
}

export default function LazyContainer({ children }: Props) {
    const canaryRef = useRef<HTMLDivElement>(null)
    const isVisible = useIsVisible(canaryRef)

    return (
        <>
            <div ref={canaryRef}></div>
            {isVisible && children}
        </>
    )
}

function useIsVisible(canaryRef: RefObject<HTMLDivElement>) {
    const entry = useIntersectionObserver(canaryRef, {
        freezeOnceVisible: true,
    })
    return !!entry?.isIntersecting
}
