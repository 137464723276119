import type { PropsWithChildren } from "react"
import React from "react"
import fetch from "isomorphic-fetch"
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    ApolloLink,
    type TypePolicies,
} from "@apollo/client"

import { useAccessToken } from "~utils/auth-hooks"
import { CustomHeaders, CLIENT_TYPES } from "~config/constants"
import pkg from "~package"
import { getUtmHeaders } from "~utils/request-helpers"

interface Props extends PropsWithChildren {
    readonly isWidget?: boolean
}

export default function Provider({ isWidget, children }: Props) {
    const accessToken = useAccessToken()
    const clientType = isWidget ? CLIENT_TYPES.WIDGET : CLIENT_TYPES.WEB

    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        })
        return forward(operation)
    })

    const utmHeaders = getUtmHeaders()

    const httpLink = createHttpLink({
        uri: process.env.GATSBY_API_URL,
        credentials: "same-origin",
        fetch,
        headers: {
            [CustomHeaders.CLIENT_NAME]: clientType,
            [CustomHeaders.CLIENT_VERSION]: pkg.version,
            ...utmHeaders,
        },
    })

    const client = new ApolloClient({
        name: clientType,
        version: pkg.version,
        link: authMiddleware.concat(httpLink),
        cache: new InMemoryCache({
            possibleTypes: { QuoteInterface: ["Quote", "DraftQuote"] },
            typePolicies: getTypePolicies(),
        }),
        credentials: "include",
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const useAuthHeaders = () => {
    const accessToken = useAccessToken()

    return {
        authContext: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
        hasAccessToken: !!accessToken,
    }
}

// eslint-disable-next-line import/no-unused-modules -- imported by test helpers
export function getTypePolicies(): TypePolicies {
    return {
        // Quote add ons don't have globally unique IDs, they use the same
        // ID as the referenced add on. In order to cache properly, apollo
        // needs to use a combination of id and package_id.
        QuoteAddOn: {
            keyFields: ["id", "package_id"],
        },
    }
}
