import React from "react"
import { Button, SHAPE } from "baseui/button"

import { useTheme } from "~config/theme"
import { navigateToGuidetechPage } from "~utils/navigation-helpers"
import { useMobileMediaQueries } from "~utils/use-media-queries"
import COPY_CONSTANTS from "~config/copy-constants"
import { useUser } from "~config/user-provider"

export default function OutfitterCallToActionButton() {
    const { shouldSeeNewOutfitterCtaInHeader } = useUser()
    const styles = useStyles()

    const { isMobileOnly } = useMobileMediaQueries()

    if (!shouldSeeNewOutfitterCtaInHeader) return null

    const copy = isMobileOnly
        ? COPY_CONSTANTS.OUTFITTERS
        : COPY_CONSTANTS.OUTFITTERS_START_HERE

    return (
        <div className={styles.buttonContainer}>
            <Button
                shape={SHAPE.pill}
                className={styles.button}
                onClick={navigateToGuidetechPage}
            >
                {copy}
            </Button>
        </div>
    )
}

function useStyles() {
    const { theme, css } = useTheme()

    return {
        buttonContainer: css({
            display: "flex",
            height: "36px",
        }),
        button: css({
            fontSize: "16px",
            backgroundColor: theme.colors.primaryOrange,
            paddingTop: theme.sizing.scale400,
            paddingRight: theme.sizing.scale800,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale800,
            ":hover": {
                backgroundColor: theme.colors.hoverPrimaryOrange,
            },
        }),
    }
}
