import { HeadingXSmall } from "baseui/typography"
import type { MouseEventHandler } from "react"
import React from "react"
import { Skeleton } from "baseui/skeleton"
import { Link } from "@reach/router"

import { useTheme } from "~config/theme"
import AspectImageWithPill from "~components/shared/aspect-image-with-pill"
import ClickableCard from "~components/shared/clickable-card"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"

import ImageCardPill from "./image-card-pill"

export interface Props {
    readonly onClick?: MouseEventHandler<HTMLButtonElement>
    readonly headerImageSrc?: string
    readonly headerImageAlt?: string
    readonly headerImageRatio?: number
    readonly aboveTitleText?: string
    readonly title: string
    readonly subtitle?: string | null
    readonly children: React.ReactNode
    readonly isLoading?: boolean
    readonly pillLabel?: string | null
    readonly topRightCornerContent?: React.ReactNode
    readonly linkTo?: string | null
    readonly testId?: string
}

export default function ImageCard({
    onClick,
    headerImageSrc,
    headerImageAlt,
    headerImageRatio = 16 / 9,
    aboveTitleText,
    title,
    subtitle,
    children,
    isLoading,
    pillLabel,
    topRightCornerContent,
    linkTo,
    testId,
}: Props) {
    const styles = useStyles({ onClick })

    const pill = <ImageCardPill label={pillLabel} />

    return (
        <ClickableCard
            className={styles.card}
            type="button"
            onClick={onClick}
            data-testid={testId}
        >
            <div className={styles.container}>
                <ConditionalRenderContainer
                    shouldRender={!!topRightCornerContent}
                    className={styles.topRightCornerComponent}
                >
                    {topRightCornerContent}
                </ConditionalRenderContainer>

                <AspectImageWithPill
                    imageSrc={headerImageSrc}
                    pill={pill}
                    isLoading={isLoading}
                    imageAlt={headerImageAlt}
                    ratio={headerImageRatio}
                    linkTo={linkTo}
                />
                <div className={styles.content}>
                    {aboveTitleText && (
                        <div className={styles.aboveTitleText}>
                            {aboveTitleText}
                        </div>
                    )}
                    <HeadingXSmall
                        as={linkTo ? Link : undefined}
                        to={linkTo ?? ""} // It will never be "" because of the ternary operator above
                        margin={0}
                        className={styles.title}
                        title={title || ""}
                    >
                        {isLoading ? <Skeleton rows={1} /> : title}
                    </HeadingXSmall>
                    {subtitle && (
                        <p className={styles.subtitle}>
                            {isLoading ? <Skeleton rows={1} /> : subtitle}
                        </p>
                    )}
                    <div>{children}</div>
                </div>
            </div>
        </ClickableCard>
    )
}

function useStyles({
    onClick,
}: {
    onClick?: MouseEventHandler<HTMLButtonElement>
}) {
    const { css, theme } = useTheme()

    const containerCursor = onClick ? "pointer" : "default"
    const titleLineCount = 2
    const titleLineHeightEm = 1.5
    const titleHeight = `${titleLineHeightEm * titleLineCount}em`
    const titleLineHeight = `${titleLineHeightEm}em`

    return {
        imagePill: css({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
        }),
        card: css({
            cursor: containerCursor,
            padding: 0,
            position: "relative",
            width: "100%",
            height: "100%",
            ":hover": {
                boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px ${theme.sizing.scale500}`,
            },
        }),
        container: css({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
        }),
        content: css({
            height: "100%",
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
            paddingBottom: theme.sizing.scale600,
            paddingTop: theme.sizing.scale600,
            borderTop: `1px solid ${theme.colors.borderOpaque}`,
            backgroundColor: theme.colors.backgroundPrimary,
        }),
        headerImage: css({
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            height: "100%",
        }),
        aboveTitleText: css({
            fontSize: "14px",
            fontWeight: 300,
            color: theme.colors.contentTertiary,
            marginBottom: "0.2em",
        }),
        subtitle: css({
            marginTop: theme.sizing.scale200,
            fontSize: "16px",
            color: theme.colors.contentTertiary,
            userSelect: "none",
            width: "100%",
        }),
        title: css({
            textDecoration: "none",
            // Show a max of two lines, then ellipsis
            // https://stackoverflow.com/a/32585024
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkitBoxOrient": "vertical",
            "-webkitLineClamp": 2,

            // Fallback
            lineHeight: titleLineHeight,
            height: titleHeight /* height is 2x line-height, so two lines will display */,
        }),
        topRightCornerComponent: css({
            position: "absolute",
            top: "13px",
            right: "13px",
            zIndex: 200,
        }),
        bottomSection: css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        }),
    }
}
