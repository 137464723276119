import React, { useState } from "react"
import type { DrawerOverrides } from "baseui/drawer"
import { Drawer, SIZE, ANCHOR } from "baseui/drawer"
import { Search } from "baseui/icon"
import { Input } from "baseui/input"
import type { InputOverrides } from "baseui/input"

import type { TestId } from "~config/test-ids"
import { TEST_IDS } from "~config/test-ids"
import MobileMenu from "~components/shared/search-input/mobile-menu/mobile-menu"

import SearchButton from "./search-button"

interface MobileSearchInputProps {
    readonly dates: Date[]
    readonly query?: string
}

export default function MobileSearchInput({
    dates,
    query,
}: MobileSearchInputProps) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [value, setValue] = useState("")

    return (
        <div data-testid={TEST_IDS.MOBILE_SEARCH_INPUT_CONTAINER}>
            <Input
                value={value}
                placeholder="Search for state or species"
                onChange={(event) => setValue(event.target.value)}
                overrides={getInputOverrides({
                    withIcon: true,
                    testId: TEST_IDS.MOBILE_SEARCH_INPUT,
                })}
                onFocus={(event) => {
                    if (!isDrawerOpen) setIsDrawerOpen(true)
                    event.target.blur() // prevent focusing again after closing drawer
                }}
            />
            <Drawer
                isOpen={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false)
                    setValue("")
                }}
                size={SIZE.full}
                anchor={ANCHOR.top}
                animate
                overrides={getDrawerOverrides()}
            >
                <MobileMenu
                    query={query}
                    dates={dates}
                    closeDrawer={() => setIsDrawerOpen(false)}
                />
            </Drawer>
        </div>
    )
}

function getInputOverrides({
    isOpen,
    withIcon,
    testId,
}: {
    isOpen?: boolean
    withIcon?: boolean
    testId?: TestId
}): InputOverrides {
    return {
        Root: {
            style: ({ $theme }) => {
                return {
                    position: "relative",
                    borderTopRightRadius: "48px",
                    borderTopLeftRadius: "48px",
                    borderBottomRightRadius: "48px",
                    borderBottomLeftRadius: "48px",
                    boxShadow: $theme.lighting.shadow600,
                    backgroundColor: $theme.colors.backgroundPrimary,
                    width: isOpen ? "calc(100% - 110px)" : "100%",
                    marginTop: isOpen ? $theme.sizing.scale600 : "0",
                    marginRight: isOpen ? $theme.sizing.scale800 : "0",
                    marginBottom: isOpen ? $theme.sizing.scale600 : "0",
                    marginLeft: isOpen ? $theme.sizing.scale800 : "0",
                    fontSize: "16px",
                    minWidth: "120px",
                    overflow: "hidden",
                    paddingRight: "0px",
                }
            },
        },
        InputContainer: {
            style: ({ $theme }) => {
                return {
                    borderTopRightRadius: "48px",
                    borderTopLeftRadius: "48px",
                    borderBottomRightRadius: "48px",
                    borderBottomLeftRadius: "48px",
                    backgroundColor: $theme.colors.backgroundPrimary,
                }
            },
        },
        Input: {
            props: {
                "data-testid": testId,
            },
            style: ({ $theme }) => {
                return {
                    paddingRight: $theme.sizing.scale600,
                    paddingLeft: $theme.sizing.scale1200,
                    [$theme.mediaQuery.small]: {
                        paddingRight: $theme.sizing.scale900,
                        paddingLeft: $theme.sizing.scale900,
                    },
                }
            },
        },
        Before: function Before() {
            if (withIcon) {
                return (
                    <Search
                        style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translate(16px, -50%)",
                            color: "#f65e38",
                        }}
                        size={25}
                    />
                )
            }

            return null
        },
        After: function After() {
            return <SearchButton />
        },
    }
}

function getDrawerOverrides(): DrawerOverrides {
    return {
        DrawerBody: {
            style: {
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                marginLeft: 0,
            },
        },
        Close: () => null,
    }
}
