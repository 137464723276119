import React from "react"
import type {
    StatefulContentRenderProp,
    StatefulPopoverProps,
} from "baseui/popover"
import { StatefulPopover as StatefulPopoverOg } from "baseui/popover"

interface Props extends Omit<StatefulPopoverProps, "content"> {
    readonly renderContent: StatefulContentRenderProp
}

export default function StatefulPopover({ renderContent, ...props }: Props) {
    return <StatefulPopoverOg {...props} content={renderContent} />
}
