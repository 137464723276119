import { withStyle } from "baseui"

import UnstyledButton from "./unstyled-button"

const ClickableCard = withStyle(UnstyledButton, ({ $theme }) => ({
    backgroundColor: $theme.colors.backgroundPrimary,
    border: `1px solid ${$theme.colors.borderOpaque}`,
    borderRadius: $theme.borders.radius500,
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 12px",
    transition: "all 0.2s ease-in-out",
    overflow: "hidden",
}))

export default ClickableCard
