export enum ItemCategory {
    PRICING_PACKAGE = "PRICING_PACKAGE",
    BOOKING = "BOOKING",
    ADD_ON = "ADD_ON",
}

export enum EntityType {
    OUTFITTERS = "outfitters",
    LISTINGS = "listings",
}

export enum GaEventName {
    SEARCH = "search",
    LOGIN = "login",
    SIGN_UP = "sign_up",
    PURCHASE = "purchase",
    BEGIN_CHECKOUT = "begin_checkout",
    SELECT_CONTENT = "select_content",
    SHARE = "share",
    SELECT_PROMOTION = "select_promotion",
    ADD_TO_CART = "add_to_cart",
    REMOVE_FROM_CART = "remove_from_cart",
}

export const GA_EVENT = "event"

export enum LoginMethod {
    AUTH0 = "auth0",
}

export enum Currency {
    USD = "USD",
}

export enum ShareMethod {
    TWITTER = "twitter",
    FACEBOOK = "facebook",
    COPY = "copy",
    EMAIL = "email",
}

export enum ShareContentType {
    LISTING = "listing",
    OUTFITTER = "outfitter",
    UNKNOWN = "unknown",
}
