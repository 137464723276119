import React from "react"
import { useStyletron } from "baseui"

import HeaderProfileMenuButton from "~components/shared/header/header-profile-menu-button"
import HeaderProfileMenuButtons from "~components/shared/header/header-profile-menu-buttons"
import LoadingSkeleton from "~components/shared/loading-skeleton"
import UserAvatar from "~components/shared/user-avatar"
import COPY_CONSTANTS from "~config/copy-constants"
import { useUser } from "~config/user-provider"
import { getFullName } from "~utils/helpers"
import {
    useFirstSectionButtonConfigs,
    useSecondSectionButtonConfigs,
    useThirdSectionButtonConfigs,
} from "~components/shared/header/header-profile-menu-button-configs"
import { navigateToAccountPage } from "~utils/navigation-helpers"

interface Props {
    readonly close: () => void
}

export default function HeaderProfileButtonMenu({ close }: Props) {
    const firstSectionButtonConfigs = useFirstSectionButtonConfigs()
    const secondSectionButtonConfigs = useSecondSectionButtonConfigs()
    const thirdSectionButtonConfigs = useThirdSectionButtonConfigs()

    const handleAccountClick = () => {
        navigateToAccountPage()
        close()
    }

    return (
        <>
            <HeaderProfileMenuButton onClick={handleAccountClick}>
                <UserInfo />
                <UserAvatar size="40px" />
            </HeaderProfileMenuButton>
            <Divider />
            <HeaderProfileMenuButtons
                configs={firstSectionButtonConfigs}
                close={close}
            />
            <Divider />
            <HeaderProfileMenuButtons
                configs={secondSectionButtonConfigs}
                close={close}
            />
            <Divider />
            <HeaderProfileMenuButtons
                configs={thirdSectionButtonConfigs}
                close={close}
            />
        </>
    )
}

function Divider() {
    const styles = useStyles()

    return <div className={styles.divider} />
}

function UserInfo() {
    const styles = useStyles()
    const { user, isFetching, isAdmin } = useUser()

    if (isFetching) return <LoadingSkeleton width="50px" height="16px" />

    const userName = getFullName(user) || COPY_CONSTANTS.USER

    return (
        <div>
            <p className={styles.userName}>{userName}</p>
            {isAdmin && (
                <p className={styles.userRole}>{COPY_CONSTANTS.ADMIN}</p>
            )}
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        divider: css({
            marginTop: theme.sizing.scale300,
            marginRight: "0",
            marginBottom: theme.sizing.scale300,
            marginLeft: "0",
            borderTop: `1px solid ${theme.colors.borderOpaque}`,
        }),
        userName: css({
            fontSize: "16px",
            fontWeight: 700,
            margin: 0,
        }),
        userRole: css({
            fontSize: "14px",
            fontWeight: 400,
            color: theme.colors.contentTertiary,
            marginTop: theme.sizing.scale100,
            marginBottom: 0,
        }),
    }
}
