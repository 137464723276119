import React from "react"
import { useStyletron } from "baseui"
import isNil from "lodash/isNil"
import { HiStar } from "react-icons/hi"

import { TEST_IDS } from "~config/test-ids"
import type { ReviewAggregationFieldsFragment } from "~graphql/generated/graphql"

// Currently not rendering the review count since there aren't many reviews yet.
// Eventually we will show rating and count: "4.3 (136)".
export default function ReviewAggregation({ reviewAggregation }: Props) {
    const styles = useStyles()
    const rating = reviewAggregation?.average_rating

    if (isNil(rating)) return null

    return (
        <div className={styles.root} data-testid={TEST_IDS.REVIEW_AGGREGATION}>
            <HiStar />
            <span>{rating}</span>
        </div>
    )
}

interface Props {
    readonly reviewAggregation:
        | ReviewAggregationFieldsFragment
        | null
        | undefined
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            display: "flex",
            alignItems: "center",
            gap: theme.sizing.scale0,
        }),
    }
}
