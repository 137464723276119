import React from "react"

import { useTheme } from "~config/theme"

interface Props {
    readonly label: string
    readonly shouldCenter?: boolean
}

export default function PageSectionHeading({
    label,
    shouldCenter = false,
}: Props) {
    const styles = useStyles()

    return <h2 className={shouldCenter ? styles.center : undefined}>{label}</h2>
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        center: css({
            marginTop: 0,
            marginBottom: 0,
            textAlign: "center",
            fontWeight: 800,
            fontSize: "25px",
            [theme.mediaQuery.large]: {
                fontSize: "30px",
            },
            [theme.mediaQuery.xlarge]: {
                fontSize: "38px",
            },
        }),
    }
}
