import React, { type PropsWithChildren } from "react"
import { useStyletron } from "baseui"
import type { IconType } from "react-icons"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly Icon?: IconType
    readonly isBold?: boolean
}
export default function MenuButtonLabelAndIcon({ label, Icon, isBold }: Props) {
    const styles = useStyles({ isBold })

    return (
        <>
            {label && <span className={styles.label}>{label}</span>}
            {Icon && <Icon className={styles.icon} />}
        </>
    )
}

function useStyles({ isBold }: { isBold?: boolean }) {
    const [css] = useStyletron()

    return {
        label: css({
            fontWeight: isBold ? 600 : 300,
        }),
        icon: css({
            fontSize: "18px",
            verticalAlign: "middle",
        }),
    }
}
