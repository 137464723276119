import { useLayoutEffect, useState } from "react"
import { useMediaQuery } from "usehooks-ts"

import { mediaQueryExpressions } from "~config/theme-responsive"

export function useMobileMediaQueries() {
    const isMobileOrLarger = useMediaQuery(mediaQueryExpressions.mobile)
    const isMobileOnly = useMediaQuery(mediaQueryExpressions.mobileOnly)

    return {
        isMobileOrLarger,
        isMobileOnly,
    }
}

export function useTabletMediaQueries() {
    const isTabletOrLarger = useMediaQuery(mediaQueryExpressions.tablet)
    const isTabletOnly = useMediaQuery(mediaQueryExpressions.tabletOnly)

    return {
        isTabletOrLarger,
        isTabletOnly,
    }
}

export function useDesktopMediaQueries() {
    const isDesktopOrLarger = useMediaQuery(mediaQueryExpressions.desktop)
    const isDesktopOnly = useMediaQuery(mediaQueryExpressions.desktopOnly)

    return {
        isDesktopOrLarger,
        isDesktopOnly,
    }
}

export function useWindowSize() {
    // Based on https://designcode.io/react-hooks-usewindowsize-hook
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    useLayoutEffect(() => {
        handleSize()
    }, [])

    return windowSize
}
