import React from "react"

import OutfitterImageCard from "~components/shared/image-card/outfitter-image-card"
import type { OutfitterForImageCard } from "~components/shared/image-card/outfitter-image-card"

import type { Props as GenericProps } from "./generic-collection"
import GenericCollection from "./generic-collection"

export interface Props extends GenericProps {
    readonly outfitters?: Readonly<OutfitterForImageCard[]> | null
}

export default function OutfittersCollection(props: Props) {
    const { outfitters, ...genericProps } = props

    return (
        <GenericCollection {...genericProps}>
            {outfitters?.map((outfitter) => (
                <OutfitterImageCard key={outfitter.id} outfitter={outfitter} />
            ))}
        </GenericCollection>
    )
}
