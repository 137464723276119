import React from "react"
import { Link } from "gatsby-link"
import { useStyletron } from "baseui"
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaHeart,
    FaYoutube,
    FaLinkedin,
} from "react-icons/fa"
import { useLocation } from "@reach/router"

import ResponsiveBlock from "~components/shared/responsive-block"
import Divider from "~components/shared/divider"
import TextLogo from "~components/shared/logo/text-logo"
import ExternalLink from "~components/shared/external-link"
import DotSeparator from "~components/shared/dot-separator"
import { GUIDE_TECH_URL, SOCIAL_LINKS } from "~config/constants"
import packageJson from "~package"
import COPY from "~config/copy-constants"
import SupportLink from "~components/shared/support-link"

export default function Footer() {
    const styles = useStyles()
    const shouldRender = useShouldRenderFooter()

    if (!shouldRender) return null

    const linkConfigs = getLinkConfigs()
    const socialLinkConfigs = getSocialLinkConfigs()

    const copyrightText = getCopyrightText()

    return (
        <ResponsiveBlock className={styles.footer}>
            <div className={styles.top}>
                <div className={styles.topLogoContainer}>
                    <TextLogo isCentered />
                    <div className={styles.titleTop}>{COPY.FOOTER_COPY}</div>

                    <div className={styles.supportLinksContainer}>
                        <h3>{COPY.CONTACT_US}</h3>
                        <div className={styles.supportLink}>
                            <SupportLink isPhone />
                        </div>

                        <div className={styles.supportLink}>
                            <SupportLink isEmail />
                        </div>
                    </div>
                </div>

                <div className={styles.topLinkContainer}>
                    {linkConfigs.map(({ to, label, isExternal }) =>
                        isExternal ? (
                            <ExternalLink key={label} to={to}>
                                <div className={styles.topLink}>{label}</div>
                            </ExternalLink>
                        ) : (
                            <div key={label} className={styles.topLink}>
                                <Link className={styles.link} to={to}>
                                    {label}
                                </Link>
                            </div>
                        )
                    )}
                </div>
            </div>
            <Divider />
            <div className={styles.bottom}>
                <div className={styles.bottomContainer}>
                    <div className={styles.titleBottom}>{copyrightText}</div>
                    <span className={styles.dotSeparator}>
                        <DotSeparator />
                    </span>
                    <div className={styles.heartSection}>
                        {COPY.MADE_WITH}
                        <FaHeart className={styles.heartIcon} />
                        {COPY.IN_BATON_ROUGE}
                    </div>
                    <span className={styles.dotSeparator}>
                        <DotSeparator />
                    </span>
                    <div className={styles.heartSection}>
                        v{packageJson.version}
                    </div>
                </div>
                <div>
                    {socialLinkConfigs.map(({ to, Icon }) => (
                        <ExternalLink key={to} to={to}>
                            <Icon className={styles.socialIcon} />
                        </ExternalLink>
                    ))}
                </div>
            </div>
        </ResponsiveBlock>
    )
}

function useShouldRenderFooter() {
    const { pathname } = useLocation()

    return ["/booking", "/wishlists", "/inbox"].every(
        (path) => !pathname.includes(path)
    )
}

function getCopyrightText() {
    const year = new Date().getFullYear()

    return `${COPY.COPYRIGHT_CHAR} ${year} ${COPY.MALLARD_BAY_INC}`
}

function getLinkConfigs() {
    return [
        {
            to: "/blog",
            label: COPY.ARTICLES,
            isExternal: false,
        },
        {
            to: "/about",
            label: COPY.ABOUT_US,
            isExternal: false,
        },
        {
            to: "/press",
            label: COPY.MALLARD_BAY_NEWS,
            isExternal: false,
        },
        {
            to: "/help",
            label: COPY.HELP_AND_SUPPORT,
            isExternal: false,
        },
        {
            to: "https://shop.mallardbay.com/",
            label: COPY.SHOP_GEAR,
            isExternal: true,
        },
        {
            to: GUIDE_TECH_URL,
            label: COPY.LIST_ON_MALLARD_BAY,
            isExternal: true,
        },
        {
            to: GUIDE_TECH_URL,
            label: COPY.DEMO_GUIDETECH,
            isExternal: true,
        },
        {
            to: "/privacy",
            label: COPY.PRIVACY,
            isExternal: true,
        },
        {
            to: "/terms",
            label: COPY.TERMS,
            isExternal: true,
        },
        {
            to: "/sitemap/v2",
            label: COPY.SITEMAP,
            isExternal: false,
        },
    ]
}

function getSocialLinkConfigs() {
    return [
        {
            to: SOCIAL_LINKS.FACEBOOK,
            Icon: FaFacebookF,
        },
        {
            to: SOCIAL_LINKS.TWITTER,
            Icon: FaTwitter,
        },
        {
            to: SOCIAL_LINKS.INSTAGRAM,
            Icon: FaInstagram,
        },
        {
            to: SOCIAL_LINKS.YOUTUBE,
            Icon: FaYoutube,
        },
        {
            to: SOCIAL_LINKS.LINKEDIN,
            Icon: FaLinkedin,
        },
    ]
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        footer: css({
            backgroundColor: theme.colors.backgroundSecondary,
            borderTop: `1px solid ${theme.colors.borderOpaque}`,
            [theme.mediaQuery.small]: {
                paddingBottom: 0,
            },
        }),
        top: css({
            textAlign: "center",
            paddingTop: theme.sizing.scale1000,
            paddingRight: 0,
            paddingBottom: theme.sizing.scale1000,
            paddingLeft: 0,
            [theme.mediaQuery.medium]: {
                paddingTop: theme.sizing.scale1600,
                paddingRight: 0,
                paddingBottom: theme.sizing.scale1600,
                paddingLeft: 0,
            },
        }),
        titleTop: css({
            paddingTop: "16px",
            paddingRight: "0",
            paddingBottom: "16px",
            paddingLeft: "0",
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.medium]: {
                fontSize: "16px",
                paddingTop: "20px",
                paddingRight: "0",
                paddingBottom: "10px",
                paddingLeft: "0",
            },
        }),
        topLogoContainer: css({
            [theme.mediaQuery.medium]: {
                paddingTop: "10px",
            },
        }),
        supportLinksContainer: css({
            marginTop: "30px",
            marginBottom: "40px",
        }),
        supportLink: css({
            marginBottom: "-8px",
        }),
        topLinkContainer: css({
            display: "grid",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            fontSize: "16px",
            fontWeight: "bold",
            gridTemplateColumns: "repeat(2, 1fr)",
            [theme.mediaQuery.medium]: {
                gridTemplateColumns: "repeat(4, 1fr)",
                fontSize: "18px",
            },
        }),
        topLink: css({
            fontWeight: "bold",
            marginTop: "8px",
            marginRight: "8px",
            marginBottom: "8px",
            marginLeft: "8px",
            minWidth: "max-content",
            textAlign: "left",
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.medium]: {
                marginTop: "12px",
                marginRight: "16px",
                marginBottom: "0",
                marginLeft: "16px",
                textAlign: "center",
            },
        }),
        link: css({
            color: "inherit",
            textDecoration: "none",
        }),
        bottom: css({
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: theme.sizing.scale800,
            paddingRight: 0,
            paddingBottom: theme.sizing.scale800,
            paddingLeft: 0,
            color: theme.colors.contentPrimary,
            fontSize: "14px",
            fontWeight: 300,
            [theme.mediaQuery.medium]: {
                flexDirection: "row",
                textAlign: "left",
            },
        }),
        bottomContainer: css({
            display: "flex",
            flexDirection: "column",
            [theme.mediaQuery.medium]: {
                flexDirection: "row",
            },
        }),
        titleBottom: css({
            marginBottom: "8px",
            [theme.mediaQuery.medium]: {
                marginBottom: 0,
            },
        }),
        heartSection: css({
            display: "flex",
            justifyContent: "center",
        }),
        heartIcon: css({
            fontSize: "14px",
            marginTop: "0px",
            marginRight: "6px",
            marginBottom: "0px",
            marginLeft: "6px",
            color: "rgb(246, 94, 56)",
        }),
        socialIcon: css({
            fontSize: "18px",
            marginTop: "16px",
            marginRight: "8px",
            marginBottom: "0",
            marginLeft: "8px",
            color: theme.colors.contentPrimary,
            ":hover": {
                cursor: "pointer",
                color: theme.colors.contentSecondary,
            },
            [theme.mediaQuery.medium]: {
                marginTop: "0",
                marginRight: "0",
                marginBottom: "0",
                marginLeft: "24px",
            },
        }),
        dotSeparator: css({
            display: "none",
            [theme.mediaQuery.medium]: {
                display: "initial",
            },
        }),
    }
}
