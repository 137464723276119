import React, { type PropsWithChildren } from "react"
import { useStyletron } from "baseui"

import ReviewAggregation from "~components/shared/price-and-rating/review-aggregation"
import type { ReviewAggregationFieldsFragment } from "~graphql/generated/graphql"
import { TEST_IDS } from "~config/test-ids"

export default function ContainerWithRating({
    reviewAggregation,
    noPadding,
    children,
}: Props) {
    const styles = useStyles({ noPadding })

    // If there are no children and no reviewAggregation, return null
    // So we don't take space
    if (!children && !reviewAggregation) {
        return null
    }

    return (
        <div
            className={styles.root}
            data-testid={TEST_IDS.CONTAINER_WITH_RATING}
        >
            <div>{children}</div>
            <ReviewAggregation reviewAggregation={reviewAggregation} />
        </div>
    )
}

export interface Props extends PropsWithChildren {
    readonly reviewAggregation:
        | ReviewAggregationFieldsFragment
        | null
        | undefined
    readonly noPadding?: boolean
}

function useStyles({ noPadding }: { noPadding?: boolean }) {
    const [css, theme] = useStyletron()

    const paddingTop = noPadding ? "0" : theme.sizing.scale500

    return {
        root: css({
            fontSize: "15px",
            fontWeight: 300,
            color: theme.colors.contentPrimary,
            paddingTop,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }),
    }
}
