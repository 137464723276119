import type { PropsWithChildren } from "react"
import React from "react"

import ResponsiveSlider from "~components/shared/responsive-slider/responsive-slider"

interface Props extends PropsWithChildren {
    readonly label: string
}

export default function GenericCollectionSlider({ label, children }: Props) {
    const childrenAsArray = Array.isArray(children) ? children : [children]
    return <ResponsiveSlider title={label} items={childrenAsArray} />
}
