import type { PropsWithChildren } from "react"
import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useMemo,
} from "react"
import { BaseProvider } from "baseui"

import { LightTheme, DarkTheme } from "~config/theme"

interface IThemeContext {
    theme: ThemeStates
    toggle: () => void
    isDarkMode: boolean
}

export enum ThemeStates {
    LIGHT = "light",
    DARK = "dark",
}

const ThemeContext = createContext<IThemeContext>({
    theme: ThemeStates.LIGHT,
    toggle: () => null,
    isDarkMode: false,
})

export function ThemeProvider({ children }: Readonly<PropsWithChildren>) {
    const { theme, toggle } = useTheme()

    const isDarkMode = theme === ThemeStates.DARK
    const activeTheme = isDarkMode ? DarkTheme : LightTheme

    const value = useMemo(
        () => ({ theme, toggle, isDarkMode }),
        [theme, isDarkMode]
    )

    return (
        <BaseProvider theme={activeTheme}>
            <ThemeContext.Provider value={value}>
                {children}
            </ThemeContext.Provider>
        </BaseProvider>
    )
}

export const useThemeContext = () => {
    return useContext(ThemeContext)
}

function useTheme() {
    const [theme, setTheme] = useState<ThemeStates>(ThemeStates.LIGHT)

    useEffect(
        () =>
            setTheme(
                localStorage.getItem("theme")
                    ? (localStorage.getItem("theme") as ThemeStates)
                    : ThemeStates.LIGHT
            ),
        []
    )

    const toggle = () => {
        const value =
            theme === ThemeStates.LIGHT ? ThemeStates.DARK : ThemeStates.LIGHT
        setTheme(value)
        localStorage.setItem("theme", value)
    }

    return { theme, toggle }
}
