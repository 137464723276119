import React from "react"
import type { IconType } from "react-icons"
import { useSnackbar } from "baseui/snackbar"
import { navigate } from "gatsby"

const DEFAULT_DURATION = 8000

export default function useSnackbarWithAction({
    message,
    actionLabel,
    actionRoute,
    Icon,
    iconColor,
    duration = DEFAULT_DURATION,
}: {
    Icon: IconType
    message: string
    actionLabel: string
    actionRoute: string
    iconColor?: string
    duration?: number
}) {
    const { enqueue } = useSnackbar()

    const showSnackbarWithAction = () =>
        enqueue(
            {
                startEnhancer: () => <Icon color={iconColor} />,
                message,
                actionMessage: actionLabel,
                actionOnClick: () => navigate(actionRoute),
            },
            duration
        )

    return { showSnackbarWithAction }
}
