import { captureException } from "@sentry/react"

import type {
    AddOnFormFieldsFragment,
    PricingPackageFieldsFragment,
    QuoteFieldsFragment,
} from "~graphql/generated/graphql"
import { TypeName } from "~config/constants"
import { centsToDollars } from "~utils/helpers"

import type { EntityType } from "./tag-manager-constants"
import {
    ItemCategory,
    GaEventName,
    GA_EVENT,
    Currency,
    LoginMethod,
} from "./tag-manager-constants"
import type {
    AddOnItem,
    EventParams,
    Item,
    SearchTerm,
    SelectPromotionParams,
    ShareParams,
} from "./tag-manager-types"

// Exported for tests
export function trackEvent(eventName: GaEventName, eventParams: EventParams) {
    if (typeof window === "undefined" || !window.gtag) {
        logMissingGtagError()
        return
    }

    try {
        window.gtag(GA_EVENT, eventName, eventParams)
    } catch (error) {
        captureException(error)
    }
}

export function trackSearch(searchTerm: SearchTerm) {
    const normalizedSearchTerm = Array.isArray(searchTerm)
        ? searchTerm.filter((term) => Boolean(term)).join(",")
        : searchTerm
    if (!normalizedSearchTerm) return
    trackEvent(GaEventName.SEARCH, { search_term: normalizedSearchTerm })
}

export function trackLogin() {
    trackEvent(GaEventName.LOGIN, { method: LoginMethod.AUTH0 })
}

export function trackPurchase({
    quote,
    bookingId,
}: {
    quote: QuoteFieldsFragment
    bookingId: string
}) {
    const addOns = mapAddOnsToItems(quote)
    const pricingPackages = mapQuotePackagesToItems(quote)
    const total = centsToDollars(quote.total)
    const params = {
        currency: Currency.USD,
        value: total,
        transaction_id: bookingId,
        coupon: quote?.coupon?.custom_code,
        items: [
            {
                item_id: bookingId,
                item_name: ItemCategory.BOOKING,
                item_category: ItemCategory.BOOKING,
                price: total,
            },
            ...pricingPackages,
            ...addOns,
        ] as Item[],
    }
    trackEvent(GaEventName.PURCHASE, params)
}

export function trackBeginCheckout({
    pricingPackage,
}: {
    pricingPackage: PricingPackageFieldsFragment
}) {
    const value = centsToDollars(pricingPackage.price)
    const params = {
        currency: Currency.USD,
        value,
        items: [
            {
                item_id: pricingPackage.id,
                item_name: pricingPackage.name,
                item_category: ItemCategory.PRICING_PACKAGE,
                price: value,
            },
        ],
    }
    trackEvent(GaEventName.BEGIN_CHECKOUT, params)
}

export function trackSelectContent({
    entityId,
    entityType,
}: {
    entityId: string
    entityType: EntityType
}) {
    trackEvent(GaEventName.SELECT_CONTENT, {
        content_id: entityId,
        content_type: entityType,
    })
}

export function trackShare(params: ShareParams) {
    trackEvent(GaEventName.SHARE, params)
}

export function trackSelectPromotion(params: SelectPromotionParams) {
    trackEvent(GaEventName.SELECT_PROMOTION, params)
}

export function trackAddToCart(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment,
    quantity: number = 1
) {
    trackEvent(GaEventName.ADD_TO_CART, {
        currency: Currency.USD,
        value: getPrice(item),
        items: getItems(item, quantity),
    })
}

export function trackRemoveFromCart(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment,
    quantity: number
) {
    trackEvent(GaEventName.REMOVE_FROM_CART, {
        currency: Currency.USD,
        value: getPrice(item),
        items: getItems(item, quantity),
    })
}

// Helpers
function mapQuotePackagesToItems(quote: QuoteFieldsFragment) {
    const quotePackages = quote.packages || []
    const quantity = 1
    return quotePackages
        .map((quotePackage) =>
            mapItemToGtmItem(quotePackage.pricing_package, quantity)
        )
        .filter((item) => Boolean(item))
}

function mapAddOnsToItems(quote: QuoteFieldsFragment) {
    const addOns = quote.add_ons || []
    return addOns
        .map((addOn) => mapItemToGtmItem(addOn, addOn.quantity))
        .filter((item) => Boolean(item))
}

function getPrice(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment
) {
    switch (item.__typename) {
        case TypeName.AddOn:
            return centsToDollars(item.unit_price)
        case TypeName.PricingPackage:
            return centsToDollars(item.price)
        default:
            return 0
    }
}

function getItems(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment,
    quantity: number
): Item[] {
    const mappedItem = mapItemToGtmItem(item, quantity)
    return mappedItem ? [mappedItem] : []
}

function mapItemToGtmItem(item: AddOnItem, quantity: number) {
    const commonProps = {
        item_id: item.id,
        item_name: item.name,
        item_category3: item.price_by_day,
        item_category4: item.price_by_guest,
        quantity,
    }

    switch (item.__typename) {
        case TypeName.AddOn:
        case TypeName.QuoteAddOn:
            return {
                ...commonProps,
                item_category: ItemCategory.ADD_ON,
                ...(item.species?.name && {
                    item_category2: item.species?.name,
                }),
                price: centsToDollars(item.unit_price),
            }
        case TypeName.PricingPackage:
            return {
                ...commonProps,
                item_category: ItemCategory.PRICING_PACKAGE,
                price: centsToDollars(item.price),
            }
        default:
            return null
    }
}

function logMissingGtagError() {
    const isWindowUndefined = typeof window === "undefined"
    const isGtagUndefined = !window.gtag

    const message = `Unable to track google tag manager event - isWindowUndefined = ${isWindowUndefined} isGtagUndefined = ${isGtagUndefined}`
    const error = new Error(message)
    captureException(error)
}
