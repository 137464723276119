import React, { useContext } from "react"

import LoadingSkeleton from "~components/shared/loading-skeleton"
import { UserContext } from "~config/user-provider"
import { useTheme } from "~config/theme"
import UnreadMessageCountBadge from "~components/shared/courier-inbox/unread-message-count-badge"
import type { UserFieldsFragment } from "~graphql/generated/graphql"

export default function Avatar() {
    const { user, isFetching } = useContext(UserContext)
    const styles = useStyles(user)

    return (
        <UnreadMessageCountBadge>
            <div className={styles.avatar}>
                {isFetching && <LoadingSkeleton circle />}
            </div>
        </UnreadMessageCountBadge>
    )
}

function useStyles(user?: UserFieldsFragment) {
    const { css, theme } = useTheme()

    return {
        avatar: css({
            height: "25px",
            width: "25px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            marginBottom: "6px",
            boxShadow: theme.lighting.shadow400,
            backgroundImage: `url(${user ? user.avatar_url : ""})`,
        }),
    }
}
