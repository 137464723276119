import React, { useState } from "react"
import { format, isBefore } from "date-fns"
import type { PopoverOverrides } from "baseui/popover"
import { PLACEMENT } from "baseui/popover"
import type { InputOverrides } from "baseui/input"
import { Input } from "baseui/input"
import { useStyletron } from "baseui"
import type { CalendarProps } from "baseui/datepicker"

import { TEST_IDS } from "~config/test-ids"
import SearchButton from "~components/shared/search-input/search-button"
import SearchDropdown from "~components/shared/search-input/search-dropdown"
import AvailabilityCalendar from "~components/pages/listing/availability-calendar"
import { Z_INDICES } from "~config/constants"
import Popover from "~components/shared/popover"
import COPY from "~config/copy-constants"

export const SUGGESTIONS_POPOVER_ID = "search-input-popover-suggestions"
export const CALENDAR_POPOVER_ID = "search-input-popover-calendar"
import { handleSearch } from "./search-helpers"

enum FilterOptions {
    Query = "query",
    Start = "start",
    End = "end",
}
type SelectedBox = FilterOptions | null

interface DesktopSearchInputProps {
    readonly dates: Date[]
    readonly query?: string
    readonly setDates: (dates: Date[]) => void
}

export default function DesktopSearchInput({
    query,
    dates,
    setDates,
}: DesktopSearchInputProps) {
    const styles = useStyles()
    const [value, setValue] = useState(query || "")
    const [selectedBox, setSelectedBox] = useState<SelectedBox>(null)

    const isQuerySelected = selectedBox === FilterOptions.Query
    const isStartSelected = selectedBox === FilterOptions.Start
    const isEndSelected = selectedBox === FilterOptions.End

    const handleCalendarChange = (newDate: { date: Date[] | Date }) => {
        onCalendarChange({
            newDate,
            isStartSelected,
            setSelectedBox,
            dates,
            setDates,
        })
    }
    const clearSelectedBox = () => setSelectedBox(null)

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault()
                handleSearch(value, dates)
            }}
            data-testid={TEST_IDS.DESKTOP_SEARCH_INPUT}
        >
            <div className={styles.inputContainer}>
                <Popover
                    isOpen={isQuerySelected}
                    content={
                        <div id={SUGGESTIONS_POPOVER_ID}>
                            <SearchDropdown
                                keyword={value}
                                onKeywordChange={setValue}
                            />
                        </div>
                    }
                    placement={PLACEMENT.bottomLeft}
                    overrides={getPopoverOverrides()}
                    onClickOutside={clearSelectedBox}
                    popoverMargin={16}
                    returnFocus
                    autoFocus
                >
                    <div style={{ flex: 1 }}>
                        <Input
                            autoFocus
                            onFocus={() => setSelectedBox(FilterOptions.Query)}
                            overrides={getLargeInputOverrides(
                                isQuerySelected,
                                TEST_IDS.HEADER_SEARCH_INPUT
                            )}
                            placeholder={COPY.SEARCH_BAR_PLACEHOLDER}
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            clearable
                        />
                    </div>
                </Popover>

                {/* Divider */}
                <div className={styles.divider} />

                <Popover
                    isOpen={isStartSelected || isEndSelected}
                    content={
                        <div id={CALENDAR_POPOVER_ID}>
                            <AvailabilityCalendar
                                minDate={new Date()}
                                includeDates={[]}
                                value={dates}
                                onChange={
                                    handleCalendarChange as CalendarProps<Date>["onChange"]
                                }
                            />
                        </div>
                    }
                    overrides={getCalendarPopoverOverrides()}
                    onClickOutside={clearSelectedBox}
                    popoverMargin={16}
                    returnFocus
                    autoFocus
                >
                    <div className={styles.dateInputContainer}>
                        <Input
                            readOnly
                            onFocus={() => setSelectedBox(FilterOptions.Start)}
                            overrides={getLargeInputOverrides(
                                isStartSelected,
                                TEST_IDS.START_DATE_BUTTON
                            )}
                            placeholder="Start date"
                            value={
                                dates.length > 0
                                    ? format(dates[0], "MMM dd, yyyy")
                                    : ""
                            }
                        />
                        <div className={styles.divider} />
                        <Input
                            readOnly
                            onFocus={() => setSelectedBox(FilterOptions.End)}
                            overrides={getLargeInputOverrides(isEndSelected)}
                            placeholder="End date"
                            value={
                                dates.length > 1
                                    ? format(dates[1], "MMM dd, yyyy")
                                    : ""
                            }
                        />
                    </div>
                </Popover>
                <SearchButton disabled={value === "" && dates.length <= 1} />
            </div>
        </form>
    )
}

type OnCalendarChangeInput = {
    newDate: { date: Date[] | Date }
    isStartSelected: boolean
    setSelectedBox: (box: SelectedBox) => void
    dates: Date[]
    setDates: (dates: Date[]) => void
}

function onCalendarChange({
    newDate,
    isStartSelected,
    setSelectedBox,
    dates,
    setDates,
}: OnCalendarChangeInput) {
    if (Array.isArray(newDate.date)) {
        if (isStartSelected) {
            setSelectedBox(FilterOptions.End)
            setDates(newDate.date)
        } else if (isBefore(newDate.date[0], dates[0])) {
            setSelectedBox(FilterOptions.Start)
            setDates(newDate.date)
        } else if (dates.length < 2) {
            setDates(newDate.date)
        } else {
            setDates([dates[0], ...newDate.date])
        }
    } else {
        setDates([newDate.date])
    }
}

function getPopoverOverrides(): PopoverOverrides {
    return {
        Body: {
            style: ({ $theme }) => ({
                borderTopLeftRadius: $theme.borders.radius500,
                borderTopRightRadius: $theme.borders.radius500,
                borderBottomRightRadius: $theme.borders.radius500,
                borderBottomLeftRadius: $theme.borders.radius500,
                overflow: "hidden",
                width: "350px",
                zIndex: Z_INDICES.POPOVER,
            }),
            props: {
                "data-testid": TEST_IDS.SEARCH_DROPDOWN,
            },
        },
        Inner: {
            style: ({ $theme }) => ({
                backgroundColor: $theme.colors.backgroundPrimary,
                borderTopLeftRadius: $theme.borders.radius500,
                borderTopRightRadius: $theme.borders.radius500,
                borderBottomRightRadius: $theme.borders.radius500,
                borderBottomLeftRadius: $theme.borders.radius500,
                paddingRight: 0,
                paddingLeft: 0,
                overflow: "auto",
                maxHeight: "400px",
            }),
        },
    }
}

function getCalendarPopoverOverrides(): PopoverOverrides {
    return {
        Body: {
            style: ({ $theme }) => ({
                borderTopLeftRadius: $theme.borders.radius500,
                borderTopRightRadius: $theme.borders.radius500,
                borderBottomRightRadius: $theme.borders.radius500,
                borderBottomLeftRadius: $theme.borders.radius500,
                overflow: "hidden",
                zIndex: Z_INDICES.POPOVER,
            }),
        },
        Inner: {
            style: ({ $theme }) => ({
                backgroundColor: $theme.colors.backgroundPrimary,
                borderTopLeftRadius: $theme.borders.radius500,
                borderTopRightRadius: $theme.borders.radius500,
                borderBottomRightRadius: $theme.borders.radius500,
                borderBottomLeftRadius: $theme.borders.radius500,
                paddingRight: 0,
                paddingLeft: 0,
                overflow: "auto",
            }),
        },
    }
}

function getLargeInputOverrides(selected = false, testId = ""): InputOverrides {
    return {
        Root: {
            props: {
                "data-testid": testId,
            },
            style: ({ $theme, $isFocused }) => {
                const color =
                    $isFocused || selected
                        ? $theme.colors.backgroundPrimary
                        : "transparent"

                return {
                    borderTopRightRadius: "40px",
                    borderTopLeftRadius: "40px",
                    borderBottomRightRadius: "40px",
                    borderBottomLeftRadius: "40px",
                    borderTopColor: color,
                    borderRightColor: color,
                    borderBottomColor: color,
                    borderLeftColor: color,
                    backgroundColor: color,
                    transitionTiming: "0s",
                    boxShadow:
                        $isFocused || selected
                            ? $theme.lighting.shadow600
                            : "none",
                    ":hover": {
                        cursor: "pointer",
                        boxShadow: $theme.lighting.shadow600,
                    },
                }
            },
        },
        InputContainer: {
            style: ({ $theme }) => ({
                backgroundColor: "transparent",
                paddingLeft: $theme.sizing.scale400,
            }),
        },
        Input: {
            style: ({ $theme }) => ({
                fontSize: "15px",
                fontWeight: 600,
                ":hover": {
                    cursor: "pointer",
                },
                "::placeholder": {
                    fontWeight: 500,
                    color: $theme.colors.contentTertiary,
                },
            }),
        },
        ClearIconContainer: {
            style: ({ $isFocused }) => ({
                display: $isFocused || selected ? "flex" : "none",
            }),
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        inputContainer: css({
            display: "flex",
            width: "100%",
            background: theme.colors.backgroundTertiary,
            border: `1px solid ${theme.colors.borderOpaque}`,
            borderRadius: "40px",
            padding: "2px",
        }),
        dateInputContainer: css({
            display: "flex",
            maxWidth: "40%",
            marginRight: "2px",
            width: "fit-content",
        }),
        closeButton: css({
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            borderTopWidth: "0px",
            borderRightWidth: "0px",
            borderBottomWidth: "0px",
            borderLeftWidth: "0px",
            backgroundColor: "transparent",
            color: theme.colors.contentPrimary,
            position: "absolute",
            top: "24px",
            left: "24px",
            fontWeight: 700,
            ":hover": {
                cursor: "pointer",
            },
        }),
        divider: css({
            minWidth: "2px",
            height: "32px",
            margin: "auto 2px",
            backgroundColor: theme.colors.borderOpaque,
        }),
    }
}
