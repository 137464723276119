import React from "react"
import { useStyletron } from "baseui"

export enum LoadingDotsSize {
    sm = "sm",
    md = "md",
    lg = "lg",
}

interface Props {
    readonly noPadding?: boolean
    readonly size?: LoadingDotsSize
    readonly inverseColor?: boolean
}

export default function LoadingDots({
    noPadding = false,
    size = LoadingDotsSize.md,
    inverseColor = false,
}: Props) {
    const styles = useStyles({ noPadding, size, inverseColor })

    return (
        <div className={styles.root}>
            <div className={styles.dot} />
            <div className={styles.dot} />
            <div className={styles.dot} />
        </div>
    )
}

function useStyles({
    noPadding,
    size,
    inverseColor,
}: {
    noPadding: boolean
    size: LoadingDotsSize
    inverseColor: boolean
}) {
    const [css, theme] = useStyletron()

    const boxSize = getBoxSize(size)
    const margin = size === "sm" ? "2px" : "3px"

    return {
        root: css({
            textAlign: "center",
            paddingTop: noPadding ? "0" : theme.sizing.scale2400,
        }),
        dot: css({
            display: "inline-block",
            height: boxSize,
            width: boxSize,
            borderTopLeftRadius: boxSize,
            borderTopRightRadius: boxSize,
            borderBottomRightRadius: boxSize,
            borderBottomLeftRadius: boxSize,
            marginTop: "0",
            marginRight: margin,
            marginBottom: "0",
            marginLeft: margin,
            backgroundColor: inverseColor
                ? theme.colors.contentInversePrimary
                : theme.colors.contentPrimary,
            animationDuration: ".6s",
            animationIterationCount: "infinite",
            animationTimingFunction: "ease-in-out",
            animationName: {
                "0%": {
                    opacity: 1,
                },
                "50%": {
                    opacity: 0.35,
                },
                "100%": {
                    opacity: 1,
                },
            },
            ":nth-child(2)": {
                animationDelay: ".15s",
            },
            ":nth-child(3)": {
                animationDelay: ".3s",
            },
        }),
    }
}

function getBoxSize(size: LoadingDotsSize) {
    switch (size) {
        case LoadingDotsSize.sm:
            return "6px"
        case LoadingDotsSize.md:
            return "8px"
        default:
            return "10px"
    }
}
