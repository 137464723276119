import React from "react"

import type { Props as ButtonProps } from "~components/shared/header/header-profile-menu-button"
import HeaderProfileMenuButton from "~components/shared/header/header-profile-menu-button"

interface Props {
    readonly configs: ButtonProps[]
    readonly close: () => void
}

export default function HeaderProfileMenuButtons({ configs, close }: Props) {
    return (
        <>
            {configs.map(
                ({ label, Icon, onClick, isBold, externalLinkUrl }) => (
                    <HeaderProfileMenuButton
                        key={label}
                        label={label}
                        Icon={Icon}
                        onClick={() => {
                            if (!onClick) return

                            onClick()
                            close()
                        }}
                        externalLinkUrl={externalLinkUrl}
                        isBold={isBold}
                    />
                )
            )}
        </>
    )
}
