import React, { useState } from "react"
import type { InputOverrides } from "baseui/input"
import { Input } from "baseui/input"
import type { SelectOverrides } from "baseui/select"
import { Select } from "baseui/select"
import type { CountryCode, PhoneNumber } from "libphonenumber-js"
import { parsePhoneNumberFromString, getCountries } from "libphonenumber-js"

import FormControl from "~components/shared/form-control"
import { useTheme } from "~config/theme"
import { TEST_IDS } from "~config/test-ids"
import COPY from "~config/copy-constants"

const DEFAULT_COUNTRY = { label: "US", value: "US" as CountryCode }

export interface Props {
    readonly id?: string
    readonly value: string
    readonly onChange: (value: string) => void
    readonly label?: string
    readonly caption?: string
    readonly placeholder?: string
}

interface CountryOption {
    label: string
    value: CountryCode
}

export default function PhoneNumberInput({
    id,
    value,
    onChange,
    label,
    caption,
    placeholder = COPY.PHONE_PLACEHOLDER,
}: Props) {
    const styles = useStyles()
    const overrides = useOverrides()

    const countryOptions = getCountryOptions()
    const parsedPhone = parsePhoneNumberFromString(value)
    const defaultCountry = getDefaultCountryOption({
        countryOptions,
        parsedPhone,
    })

    const [selectedCountryOption, setSelectedCountryOption] =
        useState(defaultCountry)

    return (
        <FormControl label={label} caption={caption}>
            <div className={styles.inputsContainer}>
                <div className={styles.selectContainer}>
                    <Select
                        overrides={overrides.select}
                        value={
                            selectedCountryOption ? [selectedCountryOption] : []
                        }
                        options={countryOptions}
                        labelKey="label"
                        valueKey="value"
                        onChange={({ option }) =>
                            setSelectedCountryOption(option as CountryOption)
                        }
                        clearable={false}
                        searchable={false}
                    />
                </div>
                <Input
                    overrides={overrides.input}
                    id={id}
                    value={parsedPhone?.formatNational() ?? value}
                    onChange={(event) =>
                        onInputChange({
                            newValue: event.target.value,
                            selectedCountryOption,
                            onChange,
                        })
                    }
                    placeholder={placeholder}
                />
            </div>
        </FormControl>
    )
}

function useStyles() {
    const { css } = useTheme()

    return {
        inputsContainer: css({
            display: "flex",
            gap: "2px",
        }),
        selectContainer: css({
            width: "110px",
        }),
    }
}

function useOverrides() {
    const select: SelectOverrides = {
        ControlContainer: {
            style: {
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
            },
        },
        Input: { props: { "data-testid": TEST_IDS.PHONE_COUNTRY_SELECT } },
    }
    const input: InputOverrides = {
        Root: {
            style: {
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
            },
        },
        Input: { props: { "data-testid": TEST_IDS.PHONE_NUMBER_INPUT } },
    }

    return { select, input }
}

function onInputChange({
    newValue,
    selectedCountryOption,
    onChange,
}: {
    newValue: string
    selectedCountryOption: CountryOption
    onChange: (value: string) => void
}) {
    const newParsedNumber = parsePhoneNumberFromString(
        newValue,
        selectedCountryOption?.value
    )

    onChange(newParsedNumber?.number ?? newValue)
}

function getCountryOptions(): CountryOption[] {
    return getCountries().map((country) => ({ label: country, value: country }))
}

function getDefaultCountryOption({
    countryOptions,
    parsedPhone,
}: {
    countryOptions: CountryOption[]
    parsedPhone: PhoneNumber | undefined
}): CountryOption {
    const foundDefault = countryOptions.find(
        (option) => option.value === parsedPhone?.country
    )

    return foundDefault ?? DEFAULT_COUNTRY
}
