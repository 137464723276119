import React, { useContext } from "react"
import { Link } from "gatsby-link"
import { useStyletron } from "baseui"
import { RiHeartLine, RiMapPin2Line, RiInboxArchiveLine } from "react-icons/ri"
import { useLocation, useMatch } from "@reach/router"

import MbLogo from "~images/logos/mb-logo-small.svg"
import WelcomeModal from "~components/pages/welcome-modal"
import { ResponsiveContext } from "~config/responsive-provider"
import { useColorModeValue } from "~utils/use-color-mode"
import ProfileTab from "~components/shared/tab-bar/profile-tab"

export default function TabBar() {
    const styles = useStyles()
    const { pathname } = useLocation()
    const {
        windowScroll: { isBottom },
    } = useContext(ResponsiveContext)
    const isTabBarHidden = shouldHideTabBar(pathname)

    const duckColor = useColorModeValue(styles.defaultDuck, styles.lightDuck)
    const isExploreActive = ["/", "/search"].includes(pathname)
    const isWishlistsActive = useMatch("/wishlists/*")
    const isTripsActive = useMatch("/trips/*")
    const isInboxActive = useMatch("/inbox/*")

    return (
        <>
            <div
                className={`${styles.root} ${isTabBarHidden && styles.hide} ${
                    isBottom && styles.bottom
                }`}
            >
                <Link
                    className={`${styles.tab} ${
                        isExploreActive && styles.colored
                    }`}
                    to="/"
                >
                    <img
                        src={MbLogo}
                        className={`${styles.duck} ${
                            isExploreActive ? styles.coloredDuck : duckColor
                        }`}
                        alt="Mallard Bay Logo"
                    />
                    <p
                        className={
                            isExploreActive ? styles.labelActive : styles.label
                        }
                    >
                        Explore
                    </p>
                </Link>
                <Link
                    className={`${styles.tab} ${
                        isWishlistsActive && styles.colored
                    }`}
                    to="/wishlists"
                >
                    <RiHeartLine className={styles.icon} />
                    <p
                        className={
                            isWishlistsActive
                                ? styles.labelActive
                                : styles.label
                        }
                    >
                        Wishlists
                    </p>
                </Link>
                <Link
                    className={`${styles.tab} ${
                        isTripsActive && styles.colored
                    }`}
                    to="/trips"
                >
                    <RiMapPin2Line className={styles.icon} />
                    <p
                        className={
                            isTripsActive ? styles.labelActive : styles.label
                        }
                    >
                        Trips
                    </p>
                </Link>
                <Link
                    className={`${styles.tab} ${
                        isInboxActive && styles.colored
                    }`}
                    to="/inbox"
                >
                    <RiInboxArchiveLine className={styles.icon} />
                    <p
                        className={
                            isInboxActive ? styles.labelActive : styles.label
                        }
                    >
                        Inbox
                    </p>
                </Link>
                <ProfileTab />
            </div>
            <WelcomeModal />
        </>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()
    const height = "68px"

    return {
        root: css({
            height,
            position: "fixed",
            bottom: "-2px",
            left: 0,
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            fontSize: "11px",
            paddingTop: "0",
            paddingRight: "2vw",
            paddingBottom: "2px",
            paddingLeft: "2vw",
            boxSizing: "border-box",
            transition: "0.25s ease-out",
            userSelect: "none",
            backgroundColor: theme.colors.backgroundPrimary,
            borderTop: `1px solid ${theme.colors.borderOpaque}`,
            [theme.mediaQuery.medium]: {
                display: "none",
            },
        }),
        hide: css({
            display: "none",
        }),
        bottom: css({
            transform: `translateY(${height})`,
        }),
        tab: css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            width: "70px",
            cursor: "pointer",
            paddingTop: "2px",
            color: theme.colors.contentTertiary,
            transition: `all ${theme.animation.timing100}`,
            opacity: 0.85,
        }),
        colored: css({
            opacity: "1 !important",
            color: "#f65e38 !important",
        }),
        duck: css({
            height: "32px",
            width: "32px",
            marginBottom: "2px",
            marginTop: "-4px",
        }),
        defaultDuck: css({
            filter: "invert(39%) sepia(0%) saturate(32%) hue-rotate(198deg) brightness(104%) contrast(88%)",
        }),
        lightDuck: css({
            filter: "invert(97%) sepia(0%) saturate(7487%) hue-rotate(143deg) brightness(123%) contrast(37%)", // #afafaf
        }),
        coloredDuck: css({
            filter: "invert(49%) sepia(28%) saturate(5991%) hue-rotate(340deg) brightness(102%) contrast(93%)", // #f65e38
        }),
        icon: css({
            height: "25px",
            width: "25px",
            marginBottom: "6px",
        }),
        label: css({
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            color: theme.colors.contentTertiary,
        }),
        labelActive: css({
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            fontWeight: 600,
            color: theme.colors.contentPrimary,
        }),
    }
}

function shouldHideTabBar(pathname: string) {
    return [
        "/listing",
        "/book",
        "/inbox",
        "/group-pay-member",
        "/checkout-summary",
    ].some((path) => pathname.includes(path))
}
