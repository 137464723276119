import React from "react"
import { Button, KIND, SIZE } from "baseui/button"

interface Props {
    readonly onClick: () => void
}

export default function ClearButton({ onClick }: Props) {
    return (
        <Button kind={KIND.tertiary} size={SIZE.compact} onClick={onClick}>
            Clear
        </Button>
    )
}
