import React from "react"
import { Button, SIZE } from "baseui/button"
import type { ButtonOverrides, ButtonProps } from "baseui/button"

import { useTheme } from "~config/theme"

interface Props extends ButtonProps {
    readonly className?: string
    readonly fullwidth?: boolean
    readonly margin?: boolean
    readonly compact?: boolean
    readonly isHidden?: boolean
    readonly href?: string
}

export default function PrimaryGradientButton({
    className,
    fullwidth = false,
    margin = true,
    compact = false,
    isHidden,
    href,
    children,
    ...buttonProps
}: Props) {
    const externalLinkProps = href ? { href, target: "_blank" } : {}
    const overrides = useStyleOverrides(fullwidth, margin)

    if (isHidden) return null

    return (
        <Button
            className={className}
            type="submit"
            size={compact ? SIZE.compact : SIZE.default}
            overrides={overrides}
            $as={href ? "a" : "button"}
            {...buttonProps}
            {...externalLinkProps}
        >
            {children}
        </Button>
    )
}

function useStyleOverrides(
    fullwidth: boolean,
    margin: boolean
): ButtonOverrides {
    const { theme } = useTheme()

    return {
        BaseButton: {
            style: ({ $theme }) => {
                const backgroundImage =
                    "linear-gradient(90deg, rgba(246,94,56,1) 0%, rgba(246,138,57,1) 50%, rgba(246,94,56,1) 100%)"

                return {
                    color: theme.colors.white,
                    fontWeight: 600,
                    width: fullwidth ? "100%" : "auto",
                    marginTop: margin ? $theme.sizing.scale600 : 0,
                    marginBottom: margin ? $theme.sizing.scale600 : 0,
                    paddingRight: $theme.sizing.scale800,
                    paddingLeft: $theme.sizing.scale800,
                    backgroundSize: "200% auto",
                    // Percentage fixes the gradient not filling the entire width on chrome.
                    backgroundPosition: "105% center",
                    boxShadow: "0 2px 8px 0 rgba(246,116,57,.4)",
                    backgroundImage,
                    ":hover": {
                        backgroundPosition: "left center",
                    },
                    ":disabled": {
                        opacity: 0.8,
                        backgroundPosition: "right center",
                        color: $theme.colors.contentInverseSecondary,
                    },
                }
            },
        },
    }
}
