import React from "react"
import { useStyletron } from "baseui"
import { Button, KIND } from "baseui/button"
import { RiHeartFill } from "react-icons/ri"

import LoadingSkeleton from "~components/shared/loading-skeleton"
import type { ListingMinimalFieldsFragment } from "~graphql/generated/graphql"
import { useUpdateWishlistMutation } from "~graphql/generated/graphql"
import Image from "~components/shared/image"
import useSnackbarWithAction from "~utils/use-snackbar-with-action"
import { getWishlistRoute } from "~utils/navigation-helpers"
import COPY from "~config/copy-constants"

export default function SaveModalLink({
    listingId,
    wishlistId,
    name,
    listings,
    setIsOpen,
}: Props) {
    const styles = useStyles()
    const showSnackbar = useAddToWishlistSnackbar({ name, wishlistId })

    const [updateWishlist, { loading: isUpdating }] = useUpdateWishlistMutation(
        {
            variables: {
                id: wishlistId,
                data: {
                    listings: listings.some(
                        (listing) => listing.id === listingId
                    )
                        ? [...listings.map((listing) => listing.id)]
                        : [...listings.map((listing) => listing.id), listingId],
                },
            },
            onCompleted: () => {
                setIsOpen(false)
                showSnackbar()
            },
        }
    )

    return (
        <Button
            isLoading={isUpdating}
            kind={KIND.tertiary}
            className={styles.root}
            onClick={() => updateWishlist()}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                }}
            >
                <div className={styles.imageWrapper}>
                    <Image
                        src={listings?.[0]?.photos?.[0]?.url}
                        className={styles.image}
                        width="100"
                        height="100"
                    />
                </div>
                <div className={styles.content}>
                    {name ? (
                        <div className={styles.name}>{name}</div>
                    ) : (
                        <LoadingSkeleton height="16px" />
                    )}
                    {listings ? (
                        <div className={styles.size}>
                            {listings
                                ? `${listings.length} ${
                                      listings.length === 1
                                          ? `listing`
                                          : `listings`
                                  }`
                                : "No listings"}
                        </div>
                    ) : (
                        <LoadingSkeleton width="50%" height="16px" />
                    )}
                </div>
            </div>
        </Button>
    )
}

interface Props {
    readonly listingId: string
    readonly wishlistId: string
    readonly name?: string
    readonly listings: ListingMinimalFieldsFragment[]
    readonly setIsOpen: (value: boolean) => void
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            maxHeight: "106px",
            minHeight: "106px",
            backgroundColor: theme.colors.backgroundPrimary,
            borderTopLeftRadius: theme.borders.radius400,
            borderTopRightRadius: theme.borders.radius400,
            borderBottomRightRadius: theme.borders.radius400,
            borderBottomLeftRadius: theme.borders.radius400,
            transitionProperty: "all",
            transitionDuration: theme.animation.timing200,
            display: "flex",
            alignItems: "center",
            width: "93%",
            [theme.mediaQuery.small]: {
                width: "60vw",
            },
            [theme.mediaQuery.large]: {
                width: "30vw",
            },
            marginTop: theme.sizing.scale100,
            marginRight: "0",
            marginBottom: theme.sizing.scale100,
            marginLeft: "0",
        }),
        imageWrapper: css({
            width: "64px",
            height: "64px",
        }),
        image: css({
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
        }),
        content: css({
            paddingTop: theme.sizing.scale500,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale500,
            paddingLeft: theme.sizing.scale600,
            textAlign: "left",
        }),
        name: css({
            fontSize: "16px",
            fontWeight: 700,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.colors.contentPrimary,
            marginBottom: theme.sizing.scale200,
        }),
        size: css({
            fontSize: "14px",
            fontWeight: 400,
            color: theme.colors.contentSecondary,
        }),
    }
}

function useAddToWishlistSnackbar({
    name,
    wishlistId,
}: {
    name?: string
    wishlistId: string
}) {
    const { showSnackbarWithAction } = useSnackbarWithAction({
        message: `${COPY.ADDED_TO} ${name}`,
        actionLabel: COPY.VIEW_LIST,
        actionRoute: getWishlistRoute(wishlistId),
        Icon: RiHeartFill,
        iconColor: "red",
    })

    return showSnackbarWithAction
}
