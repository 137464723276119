import React from "react"
import { useLocation } from "@reach/router"
import type { BlockProps } from "baseui/block"
import { Block } from "baseui/block"

import type { TestId } from "~config/test-ids"
import { MAX_WIDTH } from "~config/constants"

interface Props extends BlockProps {
    readonly className?: string
    readonly testId?: TestId
    readonly noPadding?: boolean
}

export default function ResponsiveBlock({
    children,
    testId,
    noPadding,
    ...props
}: Props) {
    const { pathname } = useLocation()
    const isNarrow = ["/search", "/booking", "/wishlists/", "/inbox"].some(
        (path) => pathname.includes(path)
    )
    const paddings = noPadding
        ? ["0"]
        : ["scale800", "scale800", "scale800", "scale800", "scale800"]
    const maxWidth = getMaxWidth({ isNarrow })

    return (
        <Block paddingLeft={paddings} paddingRight={paddings} {...props}>
            <Block
                overrides={{
                    Block: {
                        props: {
                            "data-testid": testId,
                        },
                        style: {
                            maxWidth,
                            width: "100%",
                            marginTop: "0",
                            marginRight: "auto",
                            marginBottom: "0",
                            marginLeft: "auto",
                            transition: "all 0.2s ease-in-out",
                        },
                    },
                }}
            >
                {children}
            </Block>
        </Block>
    )
}

function getMaxWidth({ isNarrow }: { isNarrow: boolean }) {
    if (isNarrow) return "auto"

    return MAX_WIDTH
}
