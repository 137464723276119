import React from "react"
import { Calendar as CalendarOg } from "baseui/datepicker"
import type { CalendarProps } from "baseui/datepicker"

interface Props extends Omit<CalendarProps, "dateLabel"> {
    readonly renderDateLabel: CalendarProps["dateLabel"]
}

export default function Calendar({ renderDateLabel, ...props }: Props) {
    return <CalendarOg {...props} dateLabel={renderDateLabel} />
}
