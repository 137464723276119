import type { PropsWithChildren } from "react"
import React from "react"
import type { BadgeOverrides } from "baseui/badge"
import { NotificationCircle } from "baseui/badge"
import { useInbox } from "@trycourier/react-hooks"

import { useTheme } from "~config/theme"
import { TEST_IDS } from "~config/test-ids"

export default function UnreadMessageCountBadge({
    children,
}: PropsWithChildren) {
    const overrides = useOverrides()
    const { unreadMessageCount } = useInbox()

    return (
        <NotificationCircle
            hidden={!unreadMessageCount}
            content={unreadMessageCount}
            overrides={overrides}
        >
            {children}
        </NotificationCircle>
    )
}

function useOverrides(): BadgeOverrides {
    const { theme } = useTheme()

    return {
        Badge: {
            props: {
                "data-testid": TEST_IDS.UNREAD_MESSAGE_COUNT_BADGE,
            },
            style: {
                backgroundColor: theme.colors.primaryOrange,
            },
        },
    }
}
